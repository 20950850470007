import { Alert, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";

import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { usePaymentMetods } from "../../../Features/paymentMethod";
import PaymentMethodDisplay from "./PaymentMethodDisplay";

export const UserPaymentInfos = () => {
  const navigate = useNavigate();
  const paymentMethod = usePaymentMetods();
  const handleAddPaymentMethod = useCallback(() => {
    navigate("/payment-infos/setup");
  }, [navigate]);

  useEffect(() => {
    console.log("paymentMethod", paymentMethod);
  }, [paymentMethod]);

  if (paymentMethod.isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">Chargement...</Typography>
      </Box>
    );
  } else if (
    paymentMethod.error ||
    !paymentMethod.data ||
    Object.keys(paymentMethod.data).length === 0 ||
    (!paymentMethod.data.card && !paymentMethod.data.sepa)
  ) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="info" sx={{ my: 2 }}>
          Vous n'avez pas défini de moyen de paiement pour le moment.
        </Alert>

        <Typography variant="body1">
          Si le club a activé la facturation automatique sur un concours, vous
          devez définir un moyen de paiement pour pouvoir y participer.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          Définir un moyen de paiement nous permet de vous facturer
          automatiquement, si le concours a eu lieu, même si vous vous inscrivez
          plusieurs mois à l'avance. Le cas échéant, vous ne serez pas facturé.
        </Typography>
        <Alert severity="warning" sx={{ my: 2 }}>
          Nous ne stockons pas vos informations de paiement, elles sont gérées
          par notre prestataire de paiement Stripe.
        </Alert>
        <Box sx={{ display: "flex", mt: 2, justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPaymentMethod}
          >
            Définir un moyen de paiement
          </Button>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ p: 2 }}>
        <PaymentMethodDisplay paymentMethod={paymentMethod} />
        <Box sx={{ display: "flex", mt: 2, justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPaymentMethod}
          >
            Modifier mon moyen de paiement
          </Button>
        </Box>
      </Box>
    );
  }
};
