import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useCallback, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { FacebookLoginButton } from "react-social-login-buttons";
import { v4 as uuid } from "uuid";
import { useGetConfig } from "../../Features/config";
import { useInternalLogin } from "../../Features/login";
import Copyright from "./Copyright/Copyright";

const defaultTheme = createTheme();

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const internalLogin = useInternalLogin();
  const navigate = useNavigate();

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    [setShowPassword]
  );

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = useCallback(
    (event) => {
      setError("");
    },
    [setError]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      const data = new FormData(event.currentTarget);
      console.log({
        email: data.get("email"),
        password: data.get("password"),
      });

      if (await internalLogin(data.get("email"), data.get("password"))) {
        setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        setError("Email ou mot de passe incorrect");
      }
    },
    [internalLogin, navigate, setError, setIsLoading]
  );
  const onFacebookLogin = useCallback(() => {
    const state = uuid();
    localStorage.setItem("FB_STATE", state);
    window.location = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}/login/facebook&state=${state}`;
  }, []);

  const handleSignUp = React.useCallback(() => {
    navigate("/login/create-account");
  }, [navigate]);

  const handleForgotPassword = React.useCallback(() => {
    navigate("/login/forgot-password");
  }, [navigate]);

  const handleHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const config = useGetConfig();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button onClick={handleHome}>
              <Avatar
                sx={{ m: 1, border: "1px solid #000" }}
                src={config?.logo}
              />
            </Button>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <OutlinedInput
                sx={{ my: 2 }}
                required
                fullWidth
                id="email"
                placeholder="Adresse email"
                name="email"
                autoComplete="email"
                autoFocus
                onKeyDown={handleKeyDown}
              />
              <OutlinedInput
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                placeholder="Mot de passe *"
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
              <Box sx={{ position: "relative" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isLoading}
                >
                  Se connecter
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-6px",
                      marginLeft: "-10px",
                    }}
                  />
                )}
              </Box>
              {process.env.NODE_ENV === "development" && (
                <FacebookLoginButton
                  onClick={onFacebookLogin}
                  disabled={isLoading}
                >
                  <span>Se connecter avec Facebook</span>
                </FacebookLoginButton>
              )}
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={handleForgotPassword}
                    disabled={isLoading}
                  >
                    Mot de passe oublié ?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={handleSignUp}
                    disabled={isLoading}
                  >
                    {"Créer un compte"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
