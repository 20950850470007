import {
  CardHeader,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";

import { t } from "@lingui/macro";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

export default function CardParameters({ params, label, onChange, elevation }) {
  const handleCheck = useCallback(
    (key) => {
      const newConfig = { ...params };
      newConfig.isChecked = !newConfig.isChecked;

      //enable all disciplines
      if (newConfig.categories) {
        newConfig.categories.forEach((d) => {
          d.isChecked = newConfig.isChecked;
        });
      }

      onChange(label, newConfig);
    },
    [params, onChange, label]
  );

  const handleVisibility = useCallback(() => {
    const newConfig = { ...params };
    newConfig.isVisible = !newConfig.isVisible;
    onChange(label, newConfig);
  }, [params, onChange, label]);

  const handleSubCategoryChange = useCallback(
    (key, newConfig) => {
      const c = { ...params };
      if (newConfig.isChecked) {
        c.isChecked = true;
      } else {
        if (newConfig.amateur) {
          newConfig.amateur.isChecked = false;

          //disable all disciplines
          if (newConfig.amateur.categories) {
            newConfig.amateur.categories.forEach((d) => {
              d.isChecked = false;
            });
          }
        }
        if (newConfig.club) {
          newConfig.club.isChecked = false;
          //disable all disciplines
          if (newConfig.club.categories) {
            newConfig.club.categories.forEach((d) => {
              d.isChecked = false;
            });
          }
        }
      }
      c[key] = newConfig;
      onChange(label, c);
    },
    [onChange, params, label]
  );

  const subCategories = useMemo(() => {
    const keys = Object.keys(params).filter(
      (key) => typeof params[key] === "object" && !Array.isArray(params[key])
    );
    return keys;
  }, [params]);

  const disciplines = useMemo(() => {
    const keys = Object.keys(params).filter(
      (key) => typeof params[key] === "object" && Array.isArray(params[key])
    );
    const tab = [];
    if (keys.length === 0) return tab;
    params[keys[0]].forEach((key) => {
      tab.push(key);
    });
    return tab;
  }, [params]);

  const getChipColor = useCallback((discipline) => {
    if (discipline.isChecked) return "success";
    if (discipline.isChecked === false) return "error";
    return "warning";
  }, []);

  const getChipIcon = useCallback((discipline) => {
    if (discipline.isChecked) return <CheckBoxIcon />;
    if (discipline.isChecked === false) return <IndeterminateCheckBoxIcon />;
    return null;
  }, []);

  const handleChipClick = useCallback(
    (discipline) => {
      const newConfig = { ...params };
      newConfig.categories.forEach((c) => {
        if (c.label === discipline.label) {
          c.isChecked = !c.isChecked;
          if (c.isChecked) {
            newConfig.isChecked = true;
          }
        }
      });
      onChange(label, newConfig);
    },
    [params, onChange, label]
  );

  return (
    <Paper elevation={elevation} sx={{ pl: elevation === 0 ? 2 : 0 }}>
      <CardHeader
        avatar={
          <FormControlLabel
            control={
              <Checkbox onChange={handleCheck} checked={params.isChecked} />
            }
            label={t({ id: label })}
          />
        }
        action={
          <IconButton
            aria-label="settings"
            onClick={handleVisibility}
            size="large"
          >
            {params.isVisible ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        }
        sx={{ py: "8px" }}
      ></CardHeader>

      <Collapse in={params.isVisible} timeout="auto" unmountOnExit>
        {subCategories.map((subCategory) => (
          <CardParameters
            key={`${label}-${subCategory}`}
            params={params[subCategory]}
            label={subCategory}
            onChange={handleSubCategoryChange}
            elevation={0}
          />
        ))}
        {disciplines.map((discipline) => (
          <Chip
            label={t({ id: discipline.label })}
            sx={{ m: "2px" }}
            key={discipline.label}
            onDelete={() => {
              handleChipClick(discipline);
            }}
            onClick={() => {
              handleChipClick(discipline);
            }}
            deleteIcon={getChipIcon(discipline)}
            color={getChipColor(discipline)}
          />
        ))}
        <Divider sx={{ width: "80%", margin: "auto" }} />
      </Collapse>
    </Paper>
  );
}
