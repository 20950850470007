import { ButtonGroup, Checkbox, Rating } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGet, usePost } from "../../../Features/apiFetch";

import { t } from "@lingui/macro";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import InfoIcon from "@mui/icons-material/Info";
import SquareIcon from "@mui/icons-material/Square";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useRecoilState } from "recoil";
import { errorAtom } from "../../../Features/atom";
import { useGetConfig } from "../../../Features/config";
import { USER_RIGHT } from "../../../Features/enums";
import { useGetUser } from "../../../Features/login";
import { usePaymentMetods } from "../../../Features/paymentMethod";
import { getLastName } from "../../../Features/utils";
import Breadcrumb from "./Breadcrumb";
import { ContestRegisterNotAvailable } from "./ContestRegisterNotAvailable";
import { ContestRegisterPaymentNotice } from "./ContestRegisterPaymentNotice";
import PriceDrawer from "./PriceDrawer";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function CreateContest() {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ratingCounter, setRatingCounter] = useState(0);
  const [priceDrawerOpen, setPriceDrawerOpen] = useState(false);
  const [billings, setBillings] = useState([]);
  const [ratingCounterInit, setRatingCounterInit] = useState(0);
  const { contestGuid, entryGuid } = useParams();
  const get = useGet();
  const post = usePost();
  const navigate = useNavigate();
  const user = useGetUser();
  const queryClient = useQueryClient();
  const paymentMethod = usePaymentMetods();
  const config = useGetConfig();

  const [subscription, setSubscription] = useState({
    contactEmail: user?.email || "",
    confirmed: false,
  });
  const [snackBar, setSnackBar] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const isModification = useMemo(() => {
    return entryGuid !== undefined;
  }, [entryGuid]);

  const isAdmin = useMemo(() => {
    return user?.right >= USER_RIGHT.ADMIN;
  }, [user]);

  const contestQuery = useQuery({
    queryKey: [`contest_${contestGuid}`],
    queryFn: () => {
      return get(`/contest/${contestGuid}`);
    },
  });

  const subscriptionQuery = useQuery({
    queryKey: [`subscription_${entryGuid}`],
    queryFn: () => {
      return get(`/contest/subscriptions/${entryGuid}`);
    },
    enabled: isModification,
  });

  useEffect(() => {
    if (subscriptionQuery?.data?.data) {
      const lastName = getLastName(
        subscriptionQuery?.data?.data.subscriberName
      );

      setSubscription({
        ...subscriptionQuery?.data?.data,
        subscriberFirstName:
          subscriptionQuery?.data?.data.subscriberName.split(" ")[0],
        subscriberLastName: lastName,
        confirmed: true,
      });

      // calcul rating counter from subscription
      let count = 0;
      for (const dateKey in subscriptionQuery?.data?.data.categories) {
        for (const category of subscriptionQuery?.data?.data.categories[
          dateKey
        ]) {
          count += category.count;
        }
      }
      setRatingCounterInit(count);
      setRatingCounter(count);
    } else {
      console.log("no subscription");
      setSubscription({ contactEmail: user?.email || "" });
    }
  }, [subscriptionQuery?.data?.data, user?.email]);

  const contest = useMemo(() => {
    return contestQuery?.data?.data;
  }, [contestQuery]);

  const isOnlinePayment = useMemo(() => {
    return contest?.payment?.mode === "online";
  }, [contest]);

  useEffect(() => {
    if (!contestQuery.isLoading && contest.status && contest.status >= 400) {
      navigate(`/404`);
    }
  }, [contest, navigate, contestQuery]);

  useEffect(() => {
    if (contest?.payment?.mode === "online" && contest?.payment?.billings) {
      const localBillings =
        billings.length > 0 ? billings : [...contest.payment.billings];
      if (subscription) {
        // remove from billings the ones that does not exist in subscription.prepaidItems
        for (const billing of localBillings) {
          if (
            subscription.prepaidItems &&
            !subscription.prepaidItems.includes(billing.description)
          ) {
            const index = localBillings.indexOf(billing);
            if (index > -1) {
              localBillings.splice(index, 1);
            }
          }
        }
      }
      setBillings(localBillings);
    } else {
      setBillings([]);
    }
  }, [contest, subscription, billings]);

  const dateDisplay = useMemo(() => {
    if (contest?.multiDays) {
      return `Du ${moment(contest?.date).format(
        "dddd Do MMMM YYYY"
      )} au ${moment(contest?.endDate).format("dddd Do MMMM YYYY")}`;
    } else {
      return moment(contest?.date).format("dddd Do MMMM YYYY");
    }
  }, [contest]);

  const isWeekBeforeContest = useMemo(() => {
    if (!contest) return false;
    return moment(contest.date).subtract(7, "days").isSameOrBefore(moment());
  }, [contest]);

  const price = useMemo(() => {
    if (!contest?.payment) return `0 €`;
    if (ratingCounter === 0) return `0 €`;
    let total = 0;

    if (contest?.payment?.mode === "online") {
      for (const billing of billings) {
        // get price with VAT
        let priceVAT = billing.price * (1 + billing.vat / 100);

        if (billing.applyOnEachTurn) {
          priceVAT *= ratingCounter;
        }

        total += priceVAT;
      }

      // Add online payment fix fees
      const applicationFeeTTC = total * 0.01;
      total += applicationFeeTTC;

      // Round to 2 decimals
      total = total.toFixed(2);
      return `${total} €`;
    } else {
      return `0 €`;
    }
  }, [billings, contest?.payment, ratingCounter]);

  const needRegisterPaymentMethod = useMemo(() => {
    if (isModification) return false;
    if (user?.right < USER_RIGHT.USER && config.connectionMode !== 0)
      return true;

    //If not online payment, subscription is always possible
    if (!isOnlinePayment) return false;

    //If online payment, subscription is possible if the user has a payment method
    if (
      paymentMethod.error ||
      !paymentMethod.data ||
      Object.keys(paymentMethod.data).length === 0 ||
      (!paymentMethod.data.card && !paymentMethod.data.sepa)
    ) {
      return true;
    }
    return false;
  }, [user, config, isModification, isOnlinePayment, paymentMethod]);

  const isPaymentMethodLoading = useMemo(() => {
    return !isModification && isOnlinePayment && paymentMethod?.isLoading;
  }, [isModification, isOnlinePayment, paymentMethod?.isLoading]);

  const handleContactEmailChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value) {
        setError(null);
        setSubscription({
          ...subscription,
          contactEmail: value,
        });
      }
    },
    [setError, setSubscription, subscription]
  );

  const handleSubscriberFirstNameChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value) {
        setError(null);
        setSubscription({
          ...subscription,
          subscriberFirstName: value,
        });
      }
    },
    [setError, setSubscription, subscription]
  );

  const handleSubscriberLastNameChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (value) {
        setError(null);
        setSubscription({
          ...subscription,
          subscriberLastName: value,
        });
      }
    },
    [setError, setSubscription, subscription]
  );

  const handleConfirmChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setError(null);
      setSubscription({
        ...subscription,
        confirmed: checked,
      });
    },
    [setError, setSubscription, subscription]
  );

  const getCategorySubscription = useCallback(
    (dateKey, category) => {
      if (!subscription?.categories) {
        return {
          count: 0,
          enabled: true,
        };
      }
      if (!isAdmin && isWeekBeforeContest) {
        return {
          count: 0,
          enabled: false,
        };
      }

      const categorySubscription = subscription?.categories[dateKey]?.find(
        (c) => c.category === category
      );
      if (categorySubscription) {
        return {
          count: categorySubscription.count,
          enabled:
            !isModification || isAdmin || !categorySubscription?.isAccepted,
        };
      }
      return {
        count: 0,
        enabled:
          !isModification || isAdmin || !categorySubscription?.isAccepted,
      };
    },
    [isAdmin, isModification, isWeekBeforeContest, subscription?.categories]
  );

  const handleCancel = useCallback(
    (event) => {
      event.preventDefault();
      if (isModification && isAdmin) {
        navigate(`/contests/${contestGuid}/manage`);
      } else if (isModification) {
        navigate(`/my-subscriptions`);
      } else {
        navigate(`/contests`);
      }
    },
    [navigate, isModification, contestGuid, isAdmin]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        setError(null);

        const formData = new FormData(event.currentTarget);

        let isCategorySelected = false;
        const selected_categories = {};
        if (contest.multiDays) {
          for (const dateKey in contest.categoriesMultiDays) {
            selected_categories[dateKey] = [];
            for (const category of contest.categoriesMultiDays[dateKey]) {
              const rating = formData.get(`rating-${dateKey}-${category}`);
              if (rating > 0) {
                selected_categories[dateKey].push({
                  category,
                  count: Number(rating),
                });
                isCategorySelected = true;
              }
            }
          }
        } else {
          selected_categories[contest.date] = [];
          for (const category of contest.categories) {
            const rating = formData.get(`rating-${category}`);
            if (rating > 0) {
              selected_categories[contest.date].push({
                category,
                count: Number(rating),
              });
              isCategorySelected = true;
            }
          }
        }

        const subscriberFirstName = formData.get("user_firstname");
        if (!subscriberFirstName) {
          setError("Veuillez remplir le prénom du cavalier");
          return;
        }
        const subscriberLastName = formData.get("user_lastname");
        if (!subscriberLastName) {
          setError("Veuillez remplir le nom du cavalier");
          return;
        }
        const subscriberName = `${subscriberFirstName.trim()} ${subscriberLastName.trim()}`;

        if (!subscription?.confirmed) {
          setError(
            "Veuillez confirmer que vous avez lu et accepté l'information d'engagement financier"
          );
          return;
        }

        if (
          isOnlinePayment &&
          isModification &&
          ratingCounter > ratingCounterInit
        ) {
          setError(
            `Vous ne pouvez pas augmenter le nombre de tours pour une inscription en ligne. Veuillez annuler l'inscription précédente avant de vous réinscrire ou selectionner au maximum ${ratingCounterInit} ${
              ratingCounterInit > 1 ? "catégories" : "catégorie"
            }.`
          );
          return;
        }

        if (!subscription?.contactEmail) {
          setError("Veuillez remplir l'email de contact");
          return;
        }

        if (!isCategorySelected) {
          setError("Veuillez sélectionner au moins une catégorie");
          return;
        }

        const comments = formData.get("comments");

        const data = {
          subscriberName,
          contactEmail: subscription?.contactEmail,
          categories: selected_categories,
          contestGuid: contest.guid,
          comments,
        };

        let result;
        if (isModification) {
          result = await post(
            `/contest/register/update?entryGuid=${entryGuid}`,
            data
          );
        } else {
          if (isOnlinePayment) {
            /// Get only the productReference from the billings
            data.billings = billings.map((billing) => {
              return billing.productReference;
            });
          }

          result = await post("/contest/register", data);
        }

        if (result.statusCode === 409) {
          const category = t({ id: result.data.category });
          const date = moment(result.data.date).format("dddd Do MMMM YYYY");
          setError(
            `Vous êtes déjà inscrit à ce concours dans la catégorie ${category} le ${date}. Veuillez annuler l'inscription précédente avant de vous réinscrire ou déselectionner cette catégorie.`
          );
          return;
        }
        if (result.statusCode !== 200) {
          setError("Une erreur est survenue. Veuillez réessayer plus tard");
          return;
        } else {
          queryClient.invalidateQueries({ queryKey: ["contests"] });
          queryClient.invalidateQueries({
            queryKey: [`subscriptions_${contestGuid}`],
          });

          // if modification, invalidate the subscription query
          if (isModification) {
            setSnackBar({
              message: "L'inscription a bien été modifiée",
              severity: "success",
            });

            if (!isAdmin) {
              navigate(`/my-subscriptions`);
            } else {
              navigate(`/contests/${contestGuid}/manage`);
            }
          } else {
            //creation
            setSnackBar({
              message: "L'inscription a bien été créée",
              severity: "success",
            });
            navigate(`/contests`);
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      contest,
      isOnlinePayment,
      subscription?.confirmed,
      subscription?.contactEmail,
      isModification,
      ratingCounter,
      ratingCounterInit,
      billings,
      post,
      entryGuid,
      queryClient,
      contestGuid,
      setSnackBar,
      isAdmin,
      navigate,
    ]
  );

  const handleRatingChange = useCallback(() => {
    setError(null);
    setTimeout(() => {
      if (!contest) return 0;
      let count = 0;
      const formElement = document.getElementById("register-form");
      if (!formElement) return 0;
      const formData = new FormData(formElement);

      if (contest.multiDays) {
        for (const dateKey in contest.categoriesMultiDays) {
          for (const category of contest.categoriesMultiDays[dateKey]) {
            const rating = formData.get(`rating-${dateKey}-${category}`);
            if (rating > 0) {
              count += Number(rating);
            }
          }
        }
      } else {
        for (const category of contest.categories) {
          const rating = formData.get(`rating-${category}`);
          if (rating > 0) {
            count += Number(rating);
          }
        }
      }
      setRatingCounter(count);
    }, 100);
  }, [contest]);

  const handlePriceDrawerOpen = useCallback(() => {
    setPriceDrawerOpen(true);
  }, []);

  const handlePriceDrawerClose = useCallback(() => {
    setPriceDrawerOpen(false);
  }, []);

  const handlePriceDelete = useCallback(
    (index) => {
      if (!billings) return;
      console.log("delete", index);
      const newPrices = [...billings];
      newPrices.splice(index, 1);
      setBillings(newPrices);
    },
    [billings]
  );

  const handlePriceReset = useCallback(() => {
    setBillings([...contest?.payment?.billings]);
  }, [contest?.payment?.billings]);

  if (contestQuery.isLoading || contestQuery.isError) return null;

  return (
    <Grid
      container
      component="main"
      sx={{
        marginTop: "86px",
        minHeight: "100%",
      }}
    >
      <CssBaseline />
      <PriceDrawer
        open={priceDrawerOpen}
        onClose={handlePriceDrawerClose}
        billings={billings}
        onDelete={handlePriceDelete}
        onReset={handlePriceReset}
        count={ratingCounter}
        isModification={isModification}
      />
      <Grid item xs={12}>
        <Breadcrumb
          contest={contest}
          isAdmin={isAdmin}
          isModification={isModification}
        />
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        lg={6}
        xl={7}
        sx={{
          backgroundImage: `url(${config?.registerBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "calc(100vh - 86px - 192px)",
          display: {
            xs: "none",
            sm: "none",
            md: "inherit",
          },
        }}
      ></Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={6}
        xl={5}
        component={Paper}
        elevation={1}
        square
      >
        <Box
          sx={{
            mb: 4,
            mt: 2,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, border: "1px solid #000" }} src={config?.logo} />
          <Typography component="h1" variant="h5">
            Concours à {contest.place}
          </Typography>
          <Typography variant="body1">{dateDisplay}</Typography>
          <Typography variant="body2">
            {t({ id: contest.discipline })}
          </Typography>

          {needRegisterPaymentMethod &&
            (isPaymentMethodLoading ? (
              <>
                <Typography variant="body1" sx={{ mx: "auto", my: 2 }}>
                  Veuillez patienter
                </Typography>
                <CircularProgress />
              </>
            ) : (
              <ContestRegisterNotAvailable user={user} />
            ))}
          {!needRegisterPaymentMethod && (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3, width: "100%", maxWidth: "500px" }}
              id="register-form"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    label="Prénom du cavalier"
                    name="user_firstname"
                    autoComplete="user_firstname"
                    fullWidth
                    value={subscription?.subscriberFirstName || ""}
                    onChange={handleSubscriberFirstNameChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    label="Nom du cavalier"
                    name="user_lastname"
                    autoComplete="user_lastname"
                    fullWidth
                    value={subscription?.subscriberLastName || ""}
                    onChange={handleSubscriberLastNameChange}
                  />
                </Grid>
              </Grid>
              {!contest.multiDays && (
                <FormGroup sx={{ mt: 4, mb: 1, mx: "auto" }} row>
                  <Grid container>
                    {contest.categories?.map((category) => {
                      const categorySubscription = getCategorySubscription(
                        contest.date,
                        category
                      );
                      return (
                        <Grid item xs={6} sm={4} key={`${category}`}>
                          <Typography component="legend">
                            {t({ id: category })}
                          </Typography>
                          <StyledRating
                            defaultValue={categorySubscription.count}
                            key={`${category}-count:${categorySubscription.count}`}
                            max={4}
                            icon={<SquareIcon fontSize="inherit" />}
                            emptyIcon={
                              <CropSquareOutlinedIcon fontSize="inherit" />
                            }
                            name={`rating-${category}`}
                            onChange={handleRatingChange}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              )}
              {contest.multiDays &&
                Object.keys(contest.categoriesMultiDays).map((dateKey) => (
                  <>
                    <Typography
                      key={dateKey}
                      variant="body1"
                      sx={{ mt: 4, fontWeight: "lighter" }}
                    >
                      {moment(dateKey).format("dddd Do MMMM YYYY")}
                    </Typography>
                    <FormGroup sx={{ mb: 1, ml: 2 }} row fullWidth>
                      <br />
                      <Grid container>
                        {contest.categoriesMultiDays[dateKey].map(
                          (category) => {
                            const categorySubscription =
                              getCategorySubscription(dateKey, category);
                            return (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                key={`${dateKey}-${category}`}
                              >
                                <Typography component="legend">
                                  {t({ id: category })}
                                </Typography>
                                <StyledRating
                                  key={`${dateKey}-${category}-count:${categorySubscription.count}`}
                                  defaultValue={categorySubscription.count}
                                  max={5}
                                  icon={<SquareIcon fontSize="inherit" />}
                                  emptyIcon={
                                    <CropSquareOutlinedIcon fontSize="inherit" />
                                  }
                                  name={`rating-${dateKey}-${category}`}
                                  disabled={!categorySubscription.enabled}
                                  onChange={handleRatingChange}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </FormGroup>
                  </>
                ))}
              <Typography variant="body2" sx={{ mb: 3 }}>
                * Choisissez le nombre de reprises par catégorie en cliquant sur
                les carrés.
              </Typography>
              {isOnlinePayment && (
                <Box textAlign={"center"} sx={{ mt: 2, mb: 3 }}>
                  <Button
                    variant="outlined"
                    endIcon={<InfoIcon />}
                    size="large"
                    onClick={handlePriceDrawerOpen}
                  >
                    {price}
                  </Button>
                </Box>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email de contact"
                name="email"
                autoComplete="email"
                value={subscription?.contactEmail || ""}
                onChange={handleContactEmailChange}
                disabled={isAdmin && isModification}
              />
              {(!isAdmin || !isModification) && (
                <TextField
                  name="comments"
                  label="Commentaires"
                  placeholder="Entrez ici vos commentaires"
                  multiline
                  fullWidth
                />
              )}
              <FormControlLabel
                sx={{ mt: 2 }}
                control={
                  <Checkbox
                    color="primary"
                    name="confirm"
                    checked={subscription?.confirmed || false}
                    disabled={isModification}
                    onChange={handleConfirmChange}
                  />
                }
                label={
                  <Typography>
                    L'inscription m'engage financièrement dès lors qu'elle est
                    validée sur la FFE *.
                  </Typography>
                }
              />
              <ContestRegisterPaymentNotice isOnline={isOnlinePayment} />
              {error && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {error}
                </Typography>
              )}
              <Box sx={{ position: "relative", mt: 3 }}>
                <ButtonGroup fullWidth>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isModification ? "Modifier" : "S'inscrire"}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ width: "initial" }}
                    onClick={handleCancel}
                    disabled={isLoading}
                  >
                    Annuler
                  </Button>
                </ButtonGroup>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-6px",
                      marginLeft: "-10px",
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
