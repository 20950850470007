export const getLastName = (name) => {
  const tab = name.split(" ");

  //lastName is from 1 to the end of the array
  let lastName = "";
  for (let i = 1; i < tab.length; i++) {
    lastName += tab[i] + " ";
  }

  // trim last name
  lastName = lastName.trim();
  return lastName;
};
