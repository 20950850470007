import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import PriceList from "./PriceList";

export default function PriceDrawer({
  open,
  onClose,
  billings,
  onDelete,
  onReset,
  count,
  isModification,
}) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ maxWidth: 400, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant="h6">Tarifs</Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <Button onClick={onClose}>
              <Close />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ textAlign: "center" }}
          >
            <PriceList
              billings={billings}
              onDelete={onDelete}
              count={count}
              onReset={onReset}
              isModification={isModification}
            />
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
