import React from "react";
import { Typography } from "@mui/material";

export const ContestRegisterPaymentNotice = ({ isOnline }) => {
  // Return a notice depending on the payment method isOnline
  return isOnline ? (
    <Typography variant="body2" sx={{ mt: 1 }}>
      * Les frais d'inscription seront débités de votre compte automatiquement
      sur le moyen de paiement que vous avez configuré, après validation par le
      club.
    </Typography>
  ) : (
    <Typography variant="body2" sx={{ mt: 1 }}>
      * Les frais d'inscription seront facturés directement par votre club.
    </Typography>
  );
};
