import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { USER_RIGHT } from "../../Features/enums";
import { useGetUser } from "../../Features/login";

export default function ManageSubscriptions() {
  const user = useGetUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoading) return;

    if (!user || user.right < USER_RIGHT.USER) {
      navigate("/403");
    }
  }, [user, navigate]);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Outlet />
      <Footer id="FOOTER" />
    </>
  );
}
