import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import React, { useCallback, useMemo } from "react";

import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { useGetConfig } from "../../Features/config";

export default function Footer() {
  const config = useGetConfig();

  const phoneDisplay = useMemo(() => {
    // Format the phone number
    if (!config?.club?.phone) return "";
    let phone = config?.club?.phone;
    phone = phone.replace(/ /g, "");
    phone = phone.replace(/-/g, "");
    // Every 2 characters, add a space
    phone = phone.match(/.{1,2}/g).join(" ");
    return phone;
  }, [config]);

  const shortName = useMemo(() => {
    if (config?.club?.shortName) {
      return config?.club?.shortName;
    }
    if (config?.club?.name) {
      return config?.club?.name;
    }
    return "Nous suivre";
  }, [config]);

  const navigate = useNavigate();
  const handleConfidentialityPolicy = useCallback(
    (event) => {
      event.preventDefault();
      navigate("/policy/confidendiality");
    },
    [navigate]
  );
  const handleServiceConditions = useCallback(
    (event) => {
      event.preventDefault();
      navigate("/conditions/service");
    },
    [navigate]
  );

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 4,
        marginTop: "auto",
        pb: 1,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              À propos
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Link
                onClick={handleConfidentialityPolicy}
                color="inherit"
                sx={{ textDecoration: "none", cursor: "pointer" }}
                href="/policy/confidendiality"
              >
                Politique de confidentialité
              </Link>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Link
                onClick={handleServiceConditions}
                color="inherit"
                sx={{ textDecoration: "none", cursor: "pointer" }}
                href="/conditions/service"
              >
                Conditions de service
              </Link>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <Link
                color="inherit"
                sx={{ textDecoration: "none", cursor: "pointer" }}
                href="#tarteaucitron"
              >
                Gestion des cookies
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Nous contacter
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {config?.club?.address}
            </Typography>
            {config?.club?.email && (
              <Typography variant="body2" color="text.secondary">
                Email:{" "}
                <Link
                  color="inherit"
                  href={`mailto:${config?.club?.email}`}
                  sx={{ textDecoration: "none" }}
                >
                  {config?.club?.email}
                </Link>
              </Typography>
            )}
            {phoneDisplay && (
              <Typography variant="body2" color="text.secondary">
                Tel:{" "}
                <Link
                  color="inherit"
                  href={`tel:${phoneDisplay}`}
                  sx={{ textDecoration: "none" }}
                >
                  {phoneDisplay}
                </Link>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {shortName}
            </Typography>
            {config?.club?.facebook && (
              <Link href={config?.club?.facebook} color="inherit">
                <Facebook />
              </Link>
            )}
            {config?.club?.instagram && (
              <Link
                href={config?.club?.instagram}
                color="inherit"
                sx={{ pl: 1, pr: 1 }}
              >
                <Instagram />
              </Link>
            )}
            {config?.club?.twitter && (
              <Link
                href={config?.club?.twitter}
                color="inherit"
                sx={{ pl: 1, pr: 1 }}
              >
                <Twitter />
              </Link>
            )}
            <Typography variant="body2" color="text.secondary" align="left">
              {"Copyright © "}
              <Link
                color="inherit"
                href="https://www.concours-equestre.fr"
                target="_blank"
              >
                VelvetHoof
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
