import { errorAtom, loadingAtom } from "../atom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useCallback } from "react";
import { usePost } from "../apiFetch";
import { useRecoilState } from "recoil";

const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (subscriptions, subscription) => {
      const subscriptionIndex = subscriptions.findIndex(
        (s) => s.guid === subscription.guid
      );
      subscriptions[subscriptionIndex] = subscription;

      queryClient.setQueryData([`user-subscriptions`], {
        data: subscriptions,
        statusCode: 200,
      });

      queryClient.invalidateQueries([
        "contests",
        `subscriptions_${subscription.contest?.guid}`,
      ]);
    },
    [queryClient]
  );
};

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useCancelSubscription = (subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  const updateSubscription = useUpdateSubscription();
  return useMutation({
    mutationFn: async ({ subscription, category, dateKey }) => {
      setLoading(true);
      const res = await post(`/contest/register/cancel`, {
        contestEntryGuid: subscription.guid,
        category: category?.category,
        dateKey: dateKey,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
      displayError("Erreur lors de l'annulation de l'inscription");
      setLoading(false);
    },
  });
};
