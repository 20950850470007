import { atom } from "recoil";

export const errorAtom = atom({
  key: "errorAtom", // unique ID (with respect to other atoms/selectors)
  default: null, // valeur par défaut (alias valeur initials)
});

export const loadingAtom = atom({
  key: "loadingAtom", // unique ID (with respect to other atoms/selectors)
  default: false, // valeur par défaut (alias valeur initials)
});

export const isStripeEnabledAtom = atom({
  key: "isStripeEnabledAtom",
  default: false,
});
