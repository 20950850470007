import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

export default function ModalEmail({ open, onClose, dest, onSubmit }) {
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(() => {
    const formData = new FormData(document.querySelector("form"));
    const message = formData.get("email-content");
    setError(null);
    if (!message) {
      setError("Veuillez entrer un message");
      return;
    }
    onSubmit(message);
  }, [onSubmit, setError]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Envoi d'un email</DialogTitle>
      <DialogContent>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          À: {dest}
        </Typography>
        <FormControl sx={{ mt: 2 }} fullWidth component="form" noValidate>
          <TextField
            name="email-content"
            label="Message"
            placeholder="Message"
            multiline
            fullWidth
            rows={4}
            sx={{ minHeight: 300, marginTop: 2 }}
          />
        </FormControl>
        {error && (
          <Typography
            sx={{ color: "red", marginTop: 2 }}
            id="modal-modal-description"
            variant="h6"
            component="h2"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus color="secondary">
          Annuler
        </Button>
        <Button onClick={handleSubmit}>Envoyer</Button>
      </DialogActions>
    </Dialog>
  );
}
