import React, { useCallback, useEffect, useMemo } from "react";

import { t } from "@lingui/macro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";

export default function Step1({ contest, handleNext }) {
  const [internalContest, setInternalContest] = React.useState(contest);
  const [error, setError] = React.useState(null);
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setError(null);
      const formData = new FormData(event.currentTarget);
      const equide = internalContest.equide || "horse";
      const type = internalContest.type || "club";
      const discipline = formData.get("row-radio-buttons-discipline");
      if (!discipline) {
        setError("Veuillez sélectionner une discipline");
        return;
      }
      const tda = formData.get("switch-tda") ? true : false;
      const multiDays = formData.get("switch-multi-days") ? true : false;
      handleNext({
        equide,
        type,
        discipline,
        tda,
        multiDays,
        categories: contest.categories || [],
        categoriesMultiDays: contest.categoriesMultiDays || {},
      });
    },
    [handleNext, internalContest.equide, internalContest.type, contest]
  );

  const canChooseEquide = useMemo(() => {
    return window.config.categories.horse && window.config.categories.pony;
  }, []);

  const disciplines = useMemo(() => {
    const disciplines = [];
    for (const [key, value] of Object.entries(
      window.config.categories[internalContest.equide || "horse"]
    )) {
      if (value) {
        disciplines.push(key);
      }
    }
    return disciplines;
  }, [internalContest.equide]);

  const canChooseType = useMemo(() => {
    return (
      window.config.categories[internalContest.equide] &&
      window.config.categories[internalContest.equide][
        internalContest.discipline
      ]?.club?.length > 0 &&
      window.config.categories[internalContest.equide] &&
      window.config.categories[internalContest.equide][
        internalContest.discipline
      ]?.amateur?.length > 0
    );
  }, [internalContest]);

  const canChooseTDA = useMemo(() => {
    const tdas = ["jumping"];
    return (
      internalContest.discipline === undefined ||
      tdas.includes(internalContest.discipline)
    );
  }, [internalContest.discipline]);

  useEffect(() => {
    setInternalContest(contest);
  }, [contest]);

  useEffect(() => {
    if (!canChooseEquide) {
      if (window.config.categories.horse) {
        setInternalContest({
          ...internalContest,
          equide: "horse",
        });
      } else if (window.config.categories.pony) {
        setInternalContest({
          ...internalContest,
          equide: "pony",
        });
      }
    } else {
      if (!internalContest.equide) {
        setInternalContest({
          ...internalContest,
          equide: "horse",
        });
      }
    }
  }, [canChooseEquide, internalContest]);

  useEffect(() => {
    if (!canChooseType) {
      if (
        window.config.categories[internalContest.equide] &&
        window.config.categories[internalContest.equide][
          internalContest.discipline
        ]?.club?.length > 0
      ) {
        if (internalContest.type !== "club") {
          setInternalContest({
            ...internalContest,
            type: "club",
          });
        }
      } else if (
        window.config.categories[internalContest.equide] &&
        window.config.categories[internalContest.equide][
          internalContest.discipline
        ]?.amateur?.length > 0
      ) {
        if (internalContest.type !== "amateur") {
          setInternalContest({
            ...internalContest,
            type: "amateur",
          });
        }
      }
    }
  }, [canChooseType, internalContest]);

  useEffect(() => {
    if (!canChooseTDA) {
      if (internalContest.tda) {
        setInternalContest({
          ...internalContest,
          tda: false,
        });
      }
    }
  }, [canChooseTDA, internalContest]);

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mb: 2, minWidth: { sm: "517px" } }}
      >
        <FormGroup sx={{ mt: 4, mb: 4 }}>
          {canChooseEquide && (
            <FormControl required sx={{ mb: 2 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Equidé
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-equide"
                value={internalContest.equide || "horse"}
                onChange={(event) => {
                  setInternalContest({
                    ...internalContest,
                    equide: event.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="horse"
                  control={<Radio />}
                  label="Cheval"
                  checked={
                    internalContest.equide === "horse" ||
                    !internalContest.equide
                  }
                />
                <FormControlLabel
                  value="pony"
                  control={<Radio />}
                  label="Poney"
                  checked={internalContest.equide === "pony"}
                />
              </RadioGroup>
            </FormControl>
          )}

          <FormControl required sx={{ mb: 2 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Discipline
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-discipline"
              value={internalContest.discipline || "jumping"}
              onChange={(event) => {
                setInternalContest({
                  ...internalContest,
                  discipline: event.target.value,
                });
              }}
            >
              {disciplines.map((discipline) => (
                <FormControlLabel
                  key={discipline}
                  value={discipline}
                  control={<Radio />}
                  label={t({ id: discipline })}
                  checked={internalContest.discipline === discipline}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {canChooseType && (
            <FormControl required sx={{ mb: 2 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Type de concours
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-type"
                value={internalContest.type || "club"}
                onChange={(event) => {
                  setInternalContest({
                    ...internalContest,
                    type: event.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="club"
                  control={<Radio />}
                  label="Club"
                  checked={
                    internalContest.type === "club" || !internalContest.type
                  }
                />
                <FormControlLabel
                  value="amateur"
                  control={<Radio />}
                  label="Amateur"
                  checked={internalContest.type === "amateur"}
                />
              </RadioGroup>
            </FormControl>
          )}
          {canChooseTDA && (
            <FormControlLabel
              control={<Switch />}
              label="TDA"
              name="switch-tda"
              checked={internalContest.tda}
              onChange={(event) => {
                setInternalContest({
                  ...internalContest,
                  tda: event.target.checked,
                });
              }}
            />
          )}
          <FormControlLabel
            control={<Switch />}
            label="Sur plusieurs jours"
            name="switch-multi-days"
            checked={internalContest.multiDays}
            onChange={(event) => {
              setInternalContest({
                ...internalContest,
                multiDays: event.target.checked,
              });
            }}
          />
          {error && <Box sx={{ color: "red", mb: 2, mt: 2 }}>{error}</Box>}
          <Box sx={{ mb: 2 }}>
            <div>
              <Button variant="contained" sx={{ mt: 1, mr: 1 }} type="submit">
                Continuer
              </Button>
            </div>
          </Box>
        </FormGroup>
      </Box>
    </>
  );
}
