import { Alert, Box, Button, Container, Stack, Tab, Tabs } from "@mui/material";
import {
  ConnectComponentsProvider,
  ConnectPayments,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import React, { useCallback, useEffect, useState } from "react";
import { useGet, usePost } from "../../../Features/apiFetch";

import PaymentConfig from "./PaymentConfig";
import PaymentError from "./PaymentError";
import { isStripeEnabledAtom } from "../../../Features/atom";
import { useRecoilState } from "recoil";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const BillingSet = () => {
  const post = usePost();
  const get = useGet();

  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [tabValue, setTabValue] = useState("1");
  const [loadConnect, setLoadConnect] = useState(null);

  const [isStripeEnabled] = useRecoilState(isStripeEnabledAtom);

  useEffect(() => {
    if (!isStripeEnabled) {
      return;
    }

    import("@stripe/connect-js").then((module) => {
      setLoadConnect(() => module.loadConnectAndInitialize);
    });
  }, [isStripeEnabled]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCreateLink = useCallback(async () => {
    try {
      const res = await get("/billing/account/link-connection");
      if (res.statusCode === 200) {
        window.open(res.data.returnUrl, "_blank");
      }
    } catch (error) {
      console.error("Error creating link:", error);
    }
  }, [get]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const res = await post("/billing/account/session");
        if (res.statusCode === 200) {
          setClientSecret(res.data.client_secret);
        }
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    if (!isStripeEnabled) {
      return;
    }

    if (!clientSecret) {
      fetchClientSecret();
    } else {
      if (!stripeConnectInstance && loadConnect) {
        try {
          const instance = loadConnect({
            publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            fetchClientSecret: () => clientSecret ?? "",
            appearance: {
              overlays: "dialog",
              variables: {
                colorPrimary: "#5a5aa8",
              },
            },
          });
          setStripeConnectInstance(instance);
        } catch (error) {
          console.error("Error loading Connect:", error);
        }
      }
    }
  }, [clientSecret, post, stripeConnectInstance, isStripeEnabled, loadConnect]);

  if (!isStripeEnabled) {
    return (
      <Container sx={{ mt: 4, mb: 4 }}>
        <Stack sx={{ alignItems: "center" }}>
          <Box>
            <Alert severity="warning" sx={{ my: 2 }}>
              Le service de paiement est actuellement désactivé.
              <br />
              Veuillez autoriser les cookies pour Stripe afin de pouvoir
              utiliser ce service.
              <br />
              <a href="#tarteaucitron">Cookies</a>
            </Alert>
          </Box>
        </Stack>
      </Container>
    );
  }

  if (!stripeConnectInstance) {
    return <div>Chargement en cours...</div>;
  }

  return (
    <Stack sx={{ alignItems: { xs: "center", sm: "flex-start" } }}>
      <Button
        onClick={handleCreateLink}
        variant="contained"
        sx={{ mt: 1, mb: 2, width: { md: "33%", xs: "50%" } }}
      >
        Dashboard Stripe
      </Button>
      <Container sx={{ mx: "auto", mt: 4, mb: 4, px: { xs: 0 } }}>
        <Tabs
          onChange={handleChange}
          value={tabValue}
          sx={{ width: { xs: "100%", sm: "inherit" } }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable"
          allowScrollButtonsMobile
        >
          <Tab label="Paiements" value="1" />
          <Tab label="Virements" value="2" />
          <Tab label="Impayés" value="3" />
          <Tab label="Configuration" value="4" />
        </Tabs>

        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <TabPanel index="1" value={tabValue}>
            <ConnectPayments />
          </TabPanel>
          <TabPanel index="2" value={tabValue}>
            <ConnectPayouts />
          </TabPanel>
          <TabPanel index="3" value={tabValue}>
            <PaymentError />
          </TabPanel>
          <TabPanel index="4" value={tabValue}>
            <PaymentConfig />
          </TabPanel>
        </ConnectComponentsProvider>
      </Container>
    </Stack>
  );
};

export default BillingSet;
