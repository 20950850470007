import * as React from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useGetConfig } from "../../../Features/config";

export default function Copyright(props) {
  const config = useGetConfig();
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"© "}
      <Link color="inherit" href={config?.club?.website}>
        {config?.club?.name}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
