import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";

import EditIcon from "@mui/icons-material/Edit";

export default function PictureCard({ picture, onChange }) {
  const handleChooseFile = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (onChange) {
        onChange(picture.key, file);
      }
      //reset input
      event.target.value = null;
    },
    [picture, onChange]
  );

  return (
    <Grid item sm={12} md={6}>
      <Card sx={{ display: "flex" }}>
        <CardContent sx={{ flex: 1, textAlign: "left" }}>
          <Typography component="div" variant="h6">
            {picture.name}
          </Typography>
          <Typography variant="body" color="text.secondary" component="div">
            {picture.dimension}
          </Typography>
          {picture.description && (
            <Typography variant="body" color="text.secondary" component="div">
              {picture.description}
            </Typography>
          )}
        </CardContent>
        <Box sx={{ minWidth: 200, minHeight: 150, position: "relative" }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id={`upload-${picture.name}`}
            onChange={handleChooseFile}
            type="file"
          />
          <CardMedia
            component="img"
            sx={{
              maxWidth: picture.square ? 150 : 200,
              maxHeight: picture.square ? 150 : "100%",
              marginLeft: "auto",
              height: picture.square ? "auto" : "100%",
            }}
            image={picture.link}
            alt={picture.name}
          />

          <label htmlFor={`upload-${picture.name}`}>
            <IconButton
              color="white"
              aria-label="upload picture"
              component="span"
              sx={{
                position: "absolute",
                right: "0px",
                top: "0px",
                color: "white",
                filter: "drop-shadow(2px 2px 2px rgb(0 0 0))",
              }}
            >
              <EditIcon />
            </IconButton>
          </label>
        </Box>
      </Card>
    </Grid>
  );
}
