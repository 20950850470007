import { Grid, Stack } from "@mui/material";
import React, { useMemo } from "react";

import { useGetConfig } from "../../../Features/config";
import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import Filter from "../../Filter";
import Sort from "../../Sort";

export default function AdministrationBar({ onFilterChange, onSort }) {
  const config = useGetConfig();

  const filterItems = useMemo(() => {
    const filters = [];
    filters.push({ type: "header", label: "Type de compte" });

    if (config?.connectionMode === 2) {
      filters.push({
        type: "item",
        label: "Utilisateur bloqué",
        value: "user_type_-1",
      });
      filters.push({ type: "item", label: "A valider", value: "user_type_0" });
    }
    filters.push({ type: "item", label: "Adhérent", value: "user_type_1" });
    filters.push({ type: "item", label: "Employé", value: "user_type_2" });
    filters.push({
      type: "item",
      label: "Administrateur",
      value: "user_type_3",
    });
    return filters;
  }, [config?.connectionMode]);

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Administration", link: "/administration" },
      { label: "Utilisateurs" },
    ];
  }, []);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <Grid
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={4}
        sx={{
          alignItems: "baseline",
        }}
        item
      >
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid>
      <Grid
        sm={12}
        xs={12}
        md={7}
        xl={8}
        lg={7}
        textAlign={"right"}
        sx={{ pr: 4, pl: 4, pt: 2 }}
        item
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: {
              xl: "end",
              lg: "end",
              md: "end",
              sm: "center",
              xs: "center",
            },
            mt: {
              sm: 1,
              md: 0,
              xs: 1,
            },
          }}
        >
          <Filter
            items={filterItems}
            allowFreeValue={true}
            localStorageKey="users-filter"
            onChange={onFilterChange}
          />
          <Sort
            defaultSort="first_name_asc"
            values={[
              "first_name_desc",
              "first_name_asc",
              "last_name_desc",
              "last_name_asc",
              "email_desc",
              "email_asc",
            ]}
            localStorageKey="users-sort"
            onChange={onSort}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
