import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useGetConfig } from "../../Features/config";
import { USER_RIGHT } from "../../Features/enums";
import { useGetUser } from "../../Features/login";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function ContestsHome() {
  const user = useGetUser();
  const config = useGetConfig();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoading || !config) return;
    if (!config?.connectionMode) return;

    if (!user || user.right < USER_RIGHT.USER) {
      navigate("/403");
    }
  }, [user, navigate, config]);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Outlet />
      <Footer id="FOOTER" />
    </>
  );
}
