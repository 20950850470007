import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { BILLING_STATE } from "../../../Features/enums";

const PaymentErrorItem = ({ payment, onRetry, onCancel }) => {
  const chipColor = useMemo(() => {
    if (payment.paymentStatus === BILLING_STATE.PROCESSING) {
      return "warning";
    } else {
      return "error";
    }
  }, [payment.paymentStatus]);

  const chipLabel = useMemo(() => {
    if (payment.paymentStatus === BILLING_STATE.PROCESSING) {
      return "en attente";
    } else {
      return "erreur";
    }
  }, [payment.paymentStatus]);

  const isActionAllowed = useMemo(() => {
    return payment.paymentStatus !== BILLING_STATE.PROCESSING;
  }, [payment.paymentStatus]);

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={2} columns={12}>
          <Grid item md={2} xs={3}>
            <Stack sx={{ textAlign: "center" }}>
              <Chip label={chipLabel} color={chipColor} size="small" />
              <Typography variant="body2">
                <Box sx={{ display: { xs: "none", sm: "inline" } }}>
                  Montant :{" "}
                </Box>
                <b>{payment.amountDue.toFixed(2)}€</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            md={1}
            sx={{ display: { sm: "none", xs: "none", md: "inherit" } }}
          ></Grid>
          <Grid item md={5} xs={9}>
            <Stack>
              <Typography variant="body2">
                <Box sx={{ display: { xs: "none", md: "inline" } }}>
                  Concours :{" "}
                </Box>
                {payment.contestPlace}, {moment(payment.date).format("LL")}
              </Typography>
              <Typography variant="body2">
                <Box sx={{ display: { xs: "none", md: "inline" } }}>
                  Cavalier :{" "}
                </Box>
                {payment.subscriberName}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4} xs={9} xsOffset={3} mdOffset={0}>
            <Stack>
              <Typography variant="body2">
                {payment.userLastName} {payment.userFirstName}
              </Typography>
              <Typography variant="body2">
                <Link href={`mailto:${payment.userEmail}`}>
                  {payment.userEmail}
                </Link>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      {isActionAllowed && (
        <CardActions>
          <Button onClick={() => onRetry(payment.guid)}>Réessayer</Button>
          <Button onClick={() => onCancel(payment.guid)}>Annuler</Button>
        </CardActions>
      )}
    </Card>
  );
};

export default PaymentErrorItem;
