import React, { useMemo } from "react";

import { t } from "@lingui/macro";
import { Stack } from "@mui/material";
import { useUsedCategories } from "../../Features/categories";
import Filter from "../Filter";
import Sort from "../Sort";

export default function ContestFilter({ onChange, onSort }) {
  const categories = useUsedCategories();

  const filterItems = useMemo(() => {
    const filters = [];
    filters.push({ type: "header", label: "Disciplines" });
    // enumerate all disciplines
    categories.forEach((category) => {
      filters.push({
        type: "item",
        label: t({ id: category }),
        value: category,
      });
    });

    filters.push({ type: "header", label: "Types d'équidés" });
    filters.push({ type: "item", label: "Cheval", value: "horse" });
    filters.push({ type: "item", label: "Poney", value: "pony" });
    filters.push({ type: "header", label: "État" });
    filters.push({ type: "item", label: "Non annulé", value: "not-cancelled" });
    filters.push({ type: "item", label: "Non dépassé", value: "not-past" });
    return filters;
  }, [categories]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: {
          xl: "end",
          lg: "end",
          md: "end",
          sm: "center",
          xs: "center",
        },
        mt: {
          sm: 1,
          md: 0,
          xs: 1,
        },
      }}
    >
      <Filter
        items={filterItems}
        allowFreeValue={true}
        localStorageKey="contests-filter"
        onChange={onChange}
      />
      <Sort
        defaultSort="contest_date_asc"
        values={[
          "contest_date_desc",
          "contest_date_asc",
          "contest_name_desc",
          "contest_name_asc",
        ]}
        localStorageKey="contests-sort"
        onChange={onSort}
      />
    </Stack>
  );
}
