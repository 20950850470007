import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { usePost } from "../../../Features/apiFetch";
import { errorAtom } from "../../../Features/atom";

const BillingNotSet = () => {
  const [isLoading, setIsLoading] = useState(false);

  const post = usePost();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const queryClient = useQueryClient();

  const handleEnroll = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await post("/billing/account/enroll");
      if (res.statusCode === 200) {
        window.location.href = res.data.returnUrl;
      } else {
        setError({
          message:
            "Erreur lors de la création du compte Stripe. Veuillez réessayer.",
          severity: "error",
        });
      }
    } catch (error) {
      setError({
        message:
          "Erreur lors de la création du compte Stripe. Veuillez réessayer.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
      queryClient.invalidateQueries("billingStatus");
    }
  }, [post, setError, queryClient]);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Création du compte Stripe
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "left" }}>
        Nous nous sommes associés à Stripe pour fournir un traitement des
        paiements sécurisé et pratique pour nos services.
        <br />
        <br />
        Stripe est l'un des leaders du marché en matière de paiement en ligne.
        <br />
        <br />
        Une fois votre compte créé, vous pourrez accepter les paiements en ligne
        pour les inscriptions de vos membres aux concours.
      </Typography>
      <Typography
        variant="body2"
        sx={{ mt: 2, textAlign: "left", fontStyle: "italic" }}
      >
        Stripe est un service réservé aux professionnels et aux associations.
        <br />
        Durant la création de votre compte, vous devrez fournir votre numéro de
        SIRET ou SIREN, ainsi que l'IBAN du compte où vous recevrez les
        paiements.
      </Typography>
      <Box sx={{ position: "relative" }}>
        <Button
          sx={{ mt: 2 }}
          onClick={handleEnroll}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          C'est parti !
        </Button>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-6px",
              marginLeft: "-10px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default BillingNotSet;
