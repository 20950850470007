import React, { useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment/moment";

export default function Step2({ contest, handleNext, handleBack }) {
  const [internalContest, setInternalContest] = React.useState(contest);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setError(null);

      const formData = new FormData(event.currentTarget);
      const place = formData.get("place-required");
      if (!place || place === "") {
        setError("Veuillez renseigner un lieu");
        return;
      }

      let date = internalContest.date;
      if (!internalContest.date) {
        setError("Veuillez renseigner la date du concours");
        return;
      }
      const endDate = internalContest.endDate;

      if (internalContest.multiDays && !endDate) {
        setError("Veuillez renseigner une date de fin");
        return;
      }
      if (internalContest.multiDays && moment(endDate).isBefore(date)) {
        setError("La date de fin doit être après la date de début");
        return;
      }

      handleNext({ ...internalContest, place, date, endDate });
    },
    [handleNext, internalContest, setError]
  );

  const handleBackClick = useCallback(
    (event) => {
      event.preventDefault();
      handleBack(internalContest);
    },
    [handleBack, internalContest]
  );

  const handleDateChange = useCallback(
    (date) => {
      const dateStr = date.format("YYYY-MM-DD");
      setInternalContest({
        ...internalContest,
        date: dateStr,
      });
    },
    [internalContest]
  );

  const handleEndDateChange = useCallback(
    (date) => {
      const dateStr = date.format("YYYY-MM-DD");
      setInternalContest({
        ...internalContest,
        endDate: dateStr,
      });
    },
    [internalContest]
  );

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <FormGroup sx={{ mt: 4, mb: 4 }}>
        <TextField
          required
          name="place-required"
          label="Lieu"
          value={internalContest.place ?? ""}
          onChange={(event) => {
            setInternalContest({
              ...internalContest,
              place: event.target.value,
            });
          }}
          sx={{ mb: 2, minWidth: { sm: "517px" } }}
        />
        {!internalContest.multiDays && (
          <StaticDatePicker
            slotProps={{
              actionBar: {
                sx: {
                  display: "none",
                },
              },
              toolbar: {
                sx: {
                  display: "none",
                },
              },
            }}
            onChange={handleDateChange}
            value={internalContest.date ? moment(internalContest.date) : null}
          />
        )}
        {internalContest.multiDays && (
          <>
            <MobileDatePicker
              label="Début du concours"
              sx={{ my: 1 }}
              slotProps={{
                toolbar: {
                  sx: {
                    display: "none",
                  },
                },
              }}
              onChange={handleDateChange}
              value={internalContest.date ? moment(internalContest.date) : null}
            />
            <MobileDatePicker
              label="Fin du concours"
              sx={{ my: 2 }}
              slotProps={{
                toolbar: {
                  sx: {
                    display: "none",
                  },
                },
              }}
              onChange={handleEndDateChange}
              value={
                internalContest.endDate ? moment(internalContest.endDate) : null
              }
            />
          </>
        )}
        {error && (
          <Box sx={{ mb: 2 }}>
            <Typography color="red">{error}</Typography>
          </Box>
        )}
        <Box sx={{ mb: 2 }}>
          <div>
            <Button variant="contained" sx={{ mt: 1, mr: 1 }} type="submit">
              Continuer
            </Button>
            <Button onClick={handleBackClick} sx={{ mt: 1, mr: 1 }}>
              Retour
            </Button>
          </div>
        </Box>
      </FormGroup>
    </Box>
  );
}
