import { Grid, Typography } from "@mui/material";

import React from "react";
import { useGetConfig } from "../../Features/config";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function Page403() {
  const config = useGetConfig();
  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid
        container
        spacing={0}
        sx={{
          width: "100%",
          marginTop: "86px",
          minHeight: "calc(100vh - 86px - 140px)",
          backgroundImage: `url(${config?.notAllowedBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          textAlign={"center"}
          sx={{ px: 4, mb: 4, display: "flex", justifyContent: "center" }}
          alignItems="flex-end"
          alignContent="space-around"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              p: 2,
              color: "#eeeeee",
            }}
          >
            Vous n'avez pas les droits pour accéder à cette page.
          </Typography>
        </Grid>
      </Grid>
      <Footer id="FOOTER" />
    </>
  );
}
