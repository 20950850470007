import { Avatar, Badge, Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import {
  useGetChipColor,
  useUsedCategories,
} from "../../../../Features/categories";

import { t } from "@lingui/macro";
import CheckIcon from "@mui/icons-material/Check";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { useNavigate } from "react-router";
import { useGetUser } from "../../../../Features/login";
import PopoverCounters from "../../../PopoverCounters/PopoverCounters";

export default function ContestCard({ contest, filters }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useGetUser();
  const navigate = useNavigate();
  const getChipColor = useGetChipColor();
  const usedCategories = useUsedCategories();

  const handleRegisterContest = useCallback(
    (contestGuid) => {
      navigate(`/contests/${contestGuid}/register`);
    },
    [navigate]
  );

  const handleManageContest = useCallback(
    (contestGuid) => {
      navigate(`/contests/${contestGuid}/manage`);
    },
    [navigate]
  );

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const isContestPassed = useMemo(() => {
    return moment(contest?.date) <= moment();
  }, [contest]);

  const isSubscribeAllowed = useMemo(() => {
    // If the contest is not at J+6 return false
    if (moment(contest?.date) <= moment().add(6, "days")) {
      return false;
    }
    return true;
  }, [contest]);

  const isContestCancelled = useMemo(() => {
    return contest?.state === 1;
  }, [contest]);

  const isContestBlocked = useMemo(() => {
    return contest?.state === 3;
  }, [contest]);

  const variant = useMemo(() => {
    if (isContestCancelled) {
      return "declined";
    } else if (isContestPassed) {
      return "passed";
    } else {
      return null;
    }
  }, [isContestPassed, isContestCancelled]);

  const tdaChip = useMemo(() => {
    if (contest?.tda) {
      return <Chip label="TDA" color="error" sx={{ mb: 1 }} />;
    }
  }, [contest]);

  const multiDaysChip = useMemo(() => {
    if (contest?.multiDays) {
      return <Chip label="Multi-jours" color="error" sx={{ mb: 1 }} />;
    }
  }, [contest]);

  const disciplineChip = useMemo(() => {
    if (!contest?.discipline) return null;

    const color = getChipColor(contest?.discipline);

    return (
      <Chip
        label={t({ id: contest?.discipline })}
        sx={{ mb: 1, backgroundColor: color[500], color: "#ffffff" }}
      />
    );
  }, [getChipColor, contest]);

  const equideChip = useMemo(() => {
    if (contest?.equide === "horse") {
      return <Chip label="Cheval" color="primary" sx={{ mb: 1 }} />;
    } else if (contest?.equide === "pony") {
      return <Chip label="Poney" color="secondary" sx={{ mb: 1 }} />;
    }
  }, [contest]);

  const typeChip = useMemo(() => {
    if (contest?.type === "amateur") {
      return <Chip label="Amateur" color="error" sx={{ mb: 1 }} />;
    } else if (contest?.type === "club") {
      return <Chip label="Club" color="secondary" sx={{ mb: 1 }} />;
    }
  }, [contest]);

  const headerAvatar = useMemo(() => {
    if (user.right > 1) {
      return (
        <Stack direction="row" spacing={1}>
          {contest.counters?.toConfirm > 0 && (
            <Avatar
              sx={{ bgcolor: "red", width: 34, height: 34 }}
              aria-label={contest.counters?.toConfirm}
              title={`${contest.counters?.toConfirm} inscription`}
              alt={`${contest.counters?.toConfirm} inscription`}
            >
              {contest.counters?.toConfirm}
            </Avatar>
          )}
          <Button sx={{ minWidth: "34px" }} onClick={handleOpen}>
            <DashboardIcon />
          </Button>
        </Stack>
      );
    }
  }, [contest, user, handleOpen]);

  const title = useMemo(() => {
    return (
      <>
        {contest.place}
        {contest.counters?.mySubscriptions > 0 && (
          <>
            {" "}
            <CheckIcon />
            {contest.counters?.mySubscriptions > 1 && (
              <Badge
                badgeContent={contest.counters?.mySubscriptions}
                color="primary"
                sx={{ right: -15, top: 0 }}
              ></Badge>
            )}
          </>
        )}
      </>
    );
  }, [contest]);

  const isFiltered = useMemo(() => {
    if (filters.length === 0) {
      return false;
    }
    if (!contest) {
      return false;
    }
    if (
      !filters.find((f) => f.value === contest?.discipline) &&
      filters.some((f) => usedCategories.includes(f.value))
    ) {
      return true;
    }
    if (
      !filters.find((f) => f.value === contest?.equide) &&
      filters.some((f) => ["horse", "pony"].includes(f.value))
    ) {
      return true;
    }
    if (
      !filters.find((f) => f.value === contest?.type) &&
      filters.some((f) => ["amateur", "club"].includes(f.value))
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "not-cancelled") &&
      isContestCancelled
    ) {
      return true;
    }
    if (filters.find((f) => f.value === "not-past") && isContestPassed) {
      return true;
    }

    for (const filter of filters) {
      if (filter.type === "free") {
        const contestPlace = contest.place.trim().toLowerCase();
        const filterValue = filter.value.trim().toLowerCase();

        if (!contestPlace.includes(filterValue)) {
          return true;
        }
      }
    }

    return false;
  }, [contest, filters, isContestCancelled, isContestPassed, usedCategories]);

  const subheader = useMemo(() => {
    if (contest?.multiDays) {
      return `Du ${moment(contest.date).format("Do MMMM YYYY")} au ${moment(
        contest.endDate
      ).format("Do MMMM YYYY")}`;
    } else {
      return moment(contest.date).format("dddd Do MMMM YYYY");
    }
  }, [contest]);

  return (
    <>
      <PopoverCounters
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        counters={contest?.counters || {}}
      />
      <Card
        sx={{
          minWidth: 275,
          ml: 4,
          mr: 4,
          mt: 1,
          mb: 1,
          backgroundColor: variant ? `${variant}.main` : "",
          color: variant ? `${variant}.contrastText` : "",
          display: isFiltered ? "none" : "",
        }}
      >
        <CardHeader title={title} subheader={subheader} action={headerAvatar} />
        <CardContent>
          {disciplineChip} {equideChip} {typeChip} {tdaChip} {multiDaysChip}
        </CardContent>
        <CardActions disableSpacing>
          {isSubscribeAllowed && !isContestCancelled && !isContestBlocked && (
            <Button
              size="small"
              onClick={() => handleRegisterContest(contest.guid)}
            >
              S'inscrire
            </Button>
          )}
          {isSubscribeAllowed && !isContestCancelled && isContestBlocked && (
            <Button size="small" disabled>
              Complet !
            </Button>
          )}
          {user?.right > 1 && (
            <Button
              size="small"
              sx={{ marginLeft: "auto" }}
              onClick={() => handleManageContest(contest.guid)}
            >
              Gérer le concours
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
}
