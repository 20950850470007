import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useGet = () => {
  const navigate = useNavigate();

  return useCallback(
    async (path) => {
      let accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.warn("AccessToken has not been initialized");
      }

      accessToken = JSON.parse(accessToken);

      try {
        const fetchOptions = {
          method: "GET",
        };

        if (accessToken) {
          fetchOptions.headers = {
            Authorization: `Bearer ${accessToken.token}`,
          };
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${path}`,
          fetchOptions
        );

        let data = {};
        try {
          data = await response.json();
        } catch {}

        if (response.status === 403) {
          //Clear the accessToken
          localStorage.setItem("accessToken", null);
          navigate("/login");
        }

        console.log("Get", path, response.status);
        try {
          return {
            data: data,
            statusCode: response.status,
          };
        } catch {
          return {
            data: data,
            statusCode: response.status,
          };
        }
      } catch (e) {
        console.warn("Error while fetching get query on api", e);
        return {
          data: {},
          statusCode: 500,
          error: e,
        };
      }
    },
    [navigate]
  );
};

export const usePost = () => {
  const navigate = useNavigate();

  return useCallback(
    async (path, data) => {
      let accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.warn("AccessToken has not been initialized");
      }

      accessToken = JSON.parse(accessToken);

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };

        if (accessToken) {
          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken.token}`,
          };
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${path}`,
          options
        );

        let result = {};
        try {
          result = await response.json();
        } catch {}

        if (response.status === 403) {
          //Clear the accessToken
          localStorage.setItem("accessToken", null);
          navigate("/login");
        }

        console.log("Post", path, response.status);
        try {
          return {
            data: result,
            statusCode: response.status,
          };
        } catch {
          return {
            data: result,
            statusCode: response.status,
          };
        }
      } catch (e) {
        console.warn("Error while fetching get query on api", e);
        return {
          data: {},
          statusCode: 500,
          error: e,
        };
      }
    },
    [navigate]
  );
};

export const usePostFormData = () => {
  return useCallback(async (path, data) => {
    let accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.warn("AccessToken has not been initialized");
      return null;
    }

    accessToken = JSON.parse(accessToken);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken.token}`,
        },
        body: data,
      });

      let result = {};
      try {
        result = await response.json();
      } catch {}

      console.log("Post", path, response.status);
      try {
        return {
          data: result,
          statusCode: response.status,
        };
      } catch {
        return {
          data: result,
          statusCode: response.status,
        };
      }
    } catch (e) {
      console.warn("Error while fetching post query on api", e);
      return {
        data: {},
        statusCode: 500,
        error: e,
      };
    }
  }, []);
};

export const usePut = () => {
  return useCallback(async (path, data, appendClubQuery = false) => {
    let accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.warn("AccessToken has not been initialized");
      return null;
    }

    accessToken = JSON.parse(accessToken);
    let url = `${process.env.REACT_APP_API_URL}${path}`;
    if (appendClubQuery) {
      url += `?club=${window?.config?.club.guid}`;
    }

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      let result = {};
      try {
        result = await response.json();
      } catch {}

      console.log("Put", path, response.status);
      try {
        return {
          data: result,
          statusCode: response.status,
        };
      } catch {
        return {
          data: result,
          statusCode: response.status,
        };
      }
    } catch (e) {
      console.warn("Error while fetching put query on api", e);
      return {
        data: {},
        statusCode: 500,
        error: e,
      };
    }
  }, []);
};

export const usePatch = () => {
  return useCallback(async (path, data, appendClubQuery = false) => {
    let accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.warn("AccessToken has not been initialized");
    }

    accessToken = JSON.parse(accessToken);
    let url = `${process.env.REACT_APP_API_URL}${path}`;
    if (appendClubQuery) {
      url += `?club=${window?.config?.club.guid}`;
    }

    try {
      const options = {
        method: "PATCH",
        headers: {},
      };
      if (data) {
        options.body = JSON.stringify(data);
        options.headers = {
          ...options.headers,
          "Content-Type": "application/json",
        };
      }
      if (accessToken) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${accessToken.token}`,
        };
      }

      const response = await fetch(url, options);

      let result = {};
      try {
        result = await response.json();
      } catch {}

      console.log("Patch", path, response.status);
      try {
        return {
          data: result,
          statusCode: response.status,
        };
      } catch {
        return {
          data: result,
          statusCode: response.status,
        };
      }
    } catch (e) {
      console.warn("Error while fetching put query on api", e);
      return {
        data: {},
        statusCode: 500,
        error: e,
      };
    }
  }, []);
};

export const useDelete = () => {
  const navigate = useNavigate();

  return useCallback(
    async (path, body) => {
      let accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.warn("AccessToken has not been initialized");
      }

      accessToken = JSON.parse(accessToken);

      try {
        const fetchOptions = {
          method: "DELETE",
          headers: {},
        };

        if (body) {
          fetchOptions.body = JSON.stringify(body);
          fetchOptions.headers = {
            ...fetchOptions.headers,
            "Content-Type": "application/json",
          };
        }

        if (accessToken) {
          fetchOptions.headers = {
            ...fetchOptions.headers,
            Authorization: `Bearer ${accessToken.token}`,
          };
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${path}`,
          fetchOptions
        );

        let data = {};
        try {
          data = await response.json();
        } catch {}

        if (response.status === 403) {
          //Clear the accessToken
          localStorage.setItem("accessToken", null);
          navigate("/login");
        }

        console.log("Delete", path, response.status);
        try {
          return {
            data: data,
            statusCode: response.status,
          };
        } catch {
          return {
            data: data,
            statusCode: response.status,
          };
        }
      } catch (e) {
        console.warn("Error while fetching delete query on api", e);
        return {
          data: {},
          statusCode: 500,
          error: e,
        };
      }
    },
    [navigate]
  );
};

export const useListContests = () => {
  const get = useGet();
  console.log("useListContests", window?.config?.club.guid);
  const data = useQuery({
    queryKey: ["contests"],
    queryFn: () => {
      return get(`/contest?club=${window?.config?.club.guid}`);
    },
    enabled: window?.config?.club.guid !== undefined,
  });

  if (data.error) {
    console.log("useListContests error", data.error);
    return null;
  }
  if (data.data) {
    console.log("useListContests result", data.data);
    if (data.data.error) {
      console.log("useListContests error", data.data.error);
      return null;
    }
    return data.data?.data || null;
  }

  return null;
};

export const useListUserSubscriptions = () => {
  const get = useGet();
  const data = useQuery({
    queryKey: ["user-subscriptions"],
    queryFn: () => {
      return get(`/account/my-subscriptions`);
    },
  });

  if (data.error) {
    console.log("useListUserSubscriptions error", data.error);
    return null;
  }
  if (data.data) {
    console.log("useListUserSubscriptions result", data.data);
    if (data.data.error) {
      console.log("useListUserSubscriptions error", data.data.error);
      return null;
    }
    return data.data?.data || null;
  }

  return null;
};

export const useGetUserPreferences = () => {
  const get = useGet();
  const data = useQuery({
    queryKey: ["user-preferences"],
    queryFn: () => {
      return get(`/account/preferences`);
    },
  });

  if (data.error) {
    console.log("useGetUserPreferences error", data.error);
    return null;
  }
  if (data.data) {
    console.log("useGetUserPreferences result", data.data);
    if (data.data.error) {
      console.log("useGetUserPreferences error", data.data.error);
      return null;
    }
    return data.data?.data || null;
  }

  return null;
};
