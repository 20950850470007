import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import ModalDialog from "../../ModalDialog/ModalDialog";

export default function PriceList({
  billings,
  onDelete,
  onReset,
  count,
  isModification,
}) {
  const [confirmConfig, setConfirmConfig] = useState(null);
  console.log("billings", billings);

  const formatPrice = useCallback(
    (item) => {
      if (count === 0) {
        return "0€";
      }

      let priceVAT = item.price * (1 + item.vat / 100);
      priceVAT = priceVAT.toFixed(2);

      let str = `${priceVAT}€`;
      if (count > 1 && item.applyOnEachTurn === true) {
        str += ` x ${count} = ${(priceVAT * count).toFixed(2)}€`;
      }

      return str;
    },
    [count]
  );

  const handleCloseConfirm = useCallback(() => {
    setConfirmConfig(null);
  }, []);

  const handleConfirmDelete = useCallback(
    (index) => {
      onDelete(index);
      handleCloseConfirm();
    },
    [onDelete, handleCloseConfirm]
  );

  const handleDelete = useCallback(
    (e, index) => {
      e.preventDefault();
      setConfirmConfig({
        title: "Supprimer ce tarif ?",
        content: `Êtes-vous sûr de vouloir supprimer le tarif "${billings[index].description}" ? Si ces frais ne peuvent vous être enlevés, le club pourra refuser votre inscription.`,
        onConfirm: () => handleConfirmDelete(index),
      });
    },
    [billings, handleConfirmDelete]
  );

  const totalTTC = useMemo(() => {
    let total = 0;
    if (count === 0) {
      return total;
    }

    billings.forEach((price) => {
      let priceVAT = price.price * (1 + price.vat / 100);
      if (count > 1 && price.applyOnEachTurn === true) {
        priceVAT = priceVAT * count;
      }
      total += priceVAT;
    });
    return total;
  }, [count, billings]);

  const totalHT = useMemo(() => {
    let total = 0;
    if (count === 0) {
      return total;
    }
    billings.forEach((price) => {
      if (count > 1 && price.applyOnEachTurn === true) {
        total += price.price * count;
      } else {
        total += price.price;
      }
    });
    return total;
  }, [count, billings]);

  const serviceFeeTTC = useMemo(() => {
    if (totalTTC === 0) {
      return 0;
    }

    const applicationFeeAmount = totalTTC * 0.01;
    return applicationFeeAmount;
  }, [totalTTC]);

  const totalTTCWithServiceFee = useMemo(() => {
    return totalTTC + serviceFeeTTC;
  }, [totalTTC, serviceFeeTTC]);

  const totalHTWithServiceFee = useMemo(() => {
    return totalTTC - totalHT; // TODO should be serviceFeeHT ?
  }, [totalTTC, totalHT]);

  return (
    <>
      <ModalDialog config={confirmConfig} onClose={handleCloseConfirm} />

      <List>
        {billings.map((price, index) => (
          <ListItem key={price.description}>
            <ListItemText
              primary={price.description}
              secondary={formatPrice(price)}
            />
            {price.userCancelable === true && !isModification && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => handleDelete(e, index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        <Divider />
        <ListItem>
          <ListItemText
            primary="Frais de gestion"
            secondary={`${Math.round(serviceFeeTTC * 100) / 100}€`}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Total"
            secondary={`${totalTTCWithServiceFee.toFixed(2)}€ (${totalHTWithServiceFee.toFixed(
              2
            )}€ TVA)`}
          />
        </ListItem>
      </List>
      {!isModification && <Button onClick={onReset}>Rétablir</Button>}
    </>
  );
}
