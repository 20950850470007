import React, { useMemo } from "react";

import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";

export default function Breadcrumb({ contest, isAdmin, isModification }) {
  const breadcrumb = useMemo(() => {
    const tab = [];
    tab.push({
      label: "Accueil",
      link: "/",
    });
    if (isModification && !isAdmin) {
      tab.push({
        label: "Inscriptions",
        link: "/my-subscriptions",
      });
    } else {
      tab.push({
        label: "Concours",
        link: "/contests",
      });
    }
    if (isModification && isAdmin) {
      tab.push({
        label: contest?.place,
        link: `/contests/${contest?.guid}/manage`,
      });
    }
    if (isModification) {
      tab.push({
        label: "Modification de l'inscription",
        link: null,
      });
    } else {
      tab.push({
        label: "Inscription",
        link: null,
      });
    }
    return tab;
  }, [isModification, contest, isAdmin]);

  return <BreadcrumbComponent items={breadcrumb} />;
}
