import { useGet } from "./apiFetch";

import { useQuery } from "@tanstack/react-query";
import { USER_RIGHT } from "./enums";
import { useGetUser } from "./login";

export const useBillingStatus = () => {
  const user = useGetUser();
  const get = useGet();
  console.log("user on billingStatus", user);
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["billingStatus", user?.data?.email],
    queryFn: async () => {
      return get(`/billing/account/status`);
    },
    enabled: user && user.right >= USER_RIGHT.MODERATOR,
  });

  return {
    billingStatus: data?.data,
    isLoading,
    isError,
    error,
  };
};
