import { CardActionArea, CardHeader } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetConfig, useIsPCO } from "../../../Features/config";
import { CLUB_BILLING_STATUS, USER_RIGHT } from "../../../Features/enums";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useBillingStatus } from "../../../Features/billing";
import { useGetUser } from "../../../Features/login";

export default function ActionCards() {
  const user = useGetUser();
  const config = useGetConfig();
  const navigate = useNavigate();
  const [dismissAlert, setDismissAlert] = useState(false);

  const handleCreateContest = () => {
    navigate("/create-contest");
  };

  const handleShowContests = () => {
    navigate("/contests");
  };

  const handleMySubscriptions = () => {
    navigate("/my-subscriptions");
  };

  const handleAdministration = () => {
    navigate("/administration");
  };

  const handleDismissBillingAlert = useCallback(() => {
    // Dismiss the billing alert in the localStorage
    localStorage.setItem("billingAlertDismissed", "true");
    setDismissAlert(true);
  }, []);

  const userAllowed = useMemo(() => {
    return user?.right >= USER_RIGHT.USER || !config?.connectionMode;
  }, [user, config?.connectionMode]);

  const billingStatus = useBillingStatus();
  const isPCO = useIsPCO();

  const displayBillingAlert = useMemo(() => {
    if (user?.right >= USER_RIGHT.ADMIN && !isPCO) {
      return (
        !dismissAlert &&
        billingStatus?.billingStatus?.accountStatus !==
          CLUB_BILLING_STATUS.ACTIVE
      );
    }
    return false;
  }, [billingStatus, user, isPCO, dismissAlert]);

  useEffect(() => {
    if (localStorage.getItem("billingAlertDismissed")) {
      setDismissAlert(true);
    }
  }, []);

  return (
    <Grid container spacing={2} sx={{ maxWidth: 900, margin: "auto", mb: 3 }}>
      {!user.email && (
        <Grid sm={12} md={12} item>
          {userAllowed && (
            <Alert severity="info">
              Créez un compte pour gérer vos inscriptions et pour être notifié
              dès qu'un concours est ajouté&nbsp;! Un seul compte suffit pour
              gérer les inscriptions de toute la famille.
            </Alert>
          )}
          {!userAllowed && (
            <Alert severity="info">
              Créez un compte pour avoir accès aux concours proposés par le club
              et pour gérer vos inscriptions&nbsp;! Un seul compte suffit pour
              gérer les inscriptions de toute la famille.
            </Alert>
          )}
        </Grid>
      )}
      {user?.right === USER_RIGHT.GUEST && user?.email && (
        <Grid sm={12} md={12} item>
          <Alert severity="warning">
            Votre compte est en cours de validation par le club. Vous ne pouvez
            pas encore gérer vos inscriptions.
          </Alert>
        </Grid>
      )}
      {user?.right === USER_RIGHT.BANNED && user?.email && (
        <Grid sm={12} md={12} item>
          <Alert severity="error">
            Votre compte a été banni par le club. Vous ne pouvez plus gérer vos
            inscriptions.
          </Alert>
        </Grid>
      )}

      {displayBillingAlert &&
        billingStatus?.billingStatus?.accountStatus ===
          CLUB_BILLING_STATUS.NOT_CREATED && (
          <Grid sm={12} md={12} item>
            <Alert severity="error" onClose={handleDismissBillingAlert}>
              <Typography variant="body2">
                Le club n'a pas encore configuré le compte Stripe nécessaire
                pour permettre à vos utilisateurs de payer en ligne leurs
                inscriptions.
                <br /> Rendez-vous dans l'administration, section "Facturation"
                ou cliquez sur ce&nbsp;
                <Link to="/administration/billing" color="inherit">
                  lien
                </Link>
                .
                <br />
                <br />
                <i>* Ce message n'apparait que pour les administrateurs.</i>
              </Typography>
            </Alert>
          </Grid>
        )}

      {displayBillingAlert &&
        billingStatus?.billingStatus?.accountStatus ===
          CLUB_BILLING_STATUS.PENDING && (
          <Grid sm={12} md={12} item>
            <Typography variant="body2">
              <Alert severity="warning" onClose={handleDismissBillingAlert}>
                Le club n'a pas finalisé la configuration du compte Stripe
                nécessaire pour permettre à vos utilisateurs de payer en ligne
                leurs inscriptions.
                <br /> Rendez-vous dans l'administration, section "Facturation"
                ou cliquez sur ce&nbsp;
                <Link to="/administration/billing" color="inherit">
                  lien
                </Link>
                .
                <br />
                <br />
                <i>* Ce message n'apparait que pour les administrateurs.</i>
              </Alert>
            </Typography>
          </Grid>
        )}

      {!isPCO &&
        user?.right >= USER_RIGHT.ADMIN &&
        billingStatus?.billingStatus?.accountStatus ===
          CLUB_BILLING_STATUS.REJECTED && (
          <Grid sm={12} md={12} item>
            <Alert severity="warning">
              Votre compte Stripe est bloqué.
              <br />
              Vous ne pouvez plus créer de concours avec paiement en ligne. Pour
              réactiver le compte, contactez nous à l'adresse suivante:{" "}
              <a href="mailto:contact@concours-equestre.fr">Service client</a>
              <br />
              <br />
              <i>* Ce message n'apparait que pour les administrateurs.</i>
            </Alert>
          </Grid>
        )}

      <Grid
        sm={12}
        md={user?.right > USER_RIGHT.USER ? 6 : 12}
        sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" } }}
        item
      >
        <Card sx={{ height: "100%" }} variant="outlined">
          <CardActionArea onClick={handleShowContests} disabled={!userAllowed}>
            <CardHeader
              title={<EmojiEventsIcon color={!userAllowed ? "disabled" : ""} />}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color={!userAllowed ? "text.secondary" : ""}
              >
                Concours
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Accéder à la liste des prochains concours proposés par le club.
                {user?.right > USER_RIGHT.USER && (
                  <Typography variant="body2" color="text.secondary">
                    En tant que staff, gérer les inscriptions aux concours.
                  </Typography>
                )}
                {user?.right <= USER_RIGHT.USER && (
                  <Typography variant="body2" color="text.secondary">
                    Pour accéder à cette page vous devez être connecté.
                  </Typography>
                )}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid
        sm={12}
        md={user?.right > USER_RIGHT.USER ? 6 : 12}
        sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" } }}
        item
      >
        <Card sx={{ height: "100%" }} variant="outlined">
          <CardActionArea
            disabled={user?.right < USER_RIGHT.USER}
            onClick={handleMySubscriptions}
          >
            <CardHeader
              title={
                <SubscriptionsIcon
                  color={user?.right < USER_RIGHT.USER ? "disabled" : ""}
                />
              }
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color={user?.right < USER_RIGHT.USER ? "text.secondary" : ""}
              >
                Inscriptions
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                fontStyle={!user ? "italic" : ""}
              >
                Accéder à la liste de vos inscriptions. <br />
                Modifier / supprimer vos inscriptions avant leur validation par
                le club sur la FFE.
                {user?.right < USER_RIGHT.USER && (
                  <Typography variant="body2" color="text.secondary">
                    Pour accéder à cette page vous devez être connecté.
                  </Typography>
                )}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {user?.right > USER_RIGHT.USER && (
        <Grid
          sm={12}
          md={user?.right === USER_RIGHT.MODERATOR ? 12 : 6}
          sx={{
            minWidth: { xs: "100%", sm: "100%", md: "auto" },
          }}
          item
        >
          <Card sx={{ height: "100%" }} variant="outlined">
            <CardActionArea onClick={handleCreateContest}>
              <CardHeader title={<AddCircleIcon />} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Créer un concours
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Créer un concours pour le club.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}

      {user?.right >= USER_RIGHT.ADMIN && (
        <Grid
          sm={12}
          md={6}
          sx={{
            minWidth: { xs: "100%", sm: "100%", md: "auto" },
          }}
          item
        >
          <Card sx={{ height: "100%" }} variant="outlined">
            <CardActionArea onClick={handleAdministration}>
              <CardHeader title={<SupervisorAccountIcon />} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Administration
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Gérer les droits des utilisateurs.
                  <br /> Paramétrer les catégories et discplines de concours.
                  <br /> Gérer la facturation.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
