import React, { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { usePost } from "../../Features/apiFetch";
import { errorAtom } from "../../Features/atom";
import { useGetConfig } from "../../Features/config";
import { USER_RIGHT } from "../../Features/enums";
import { useLocalStorage } from "../../Features/localStorage";
import { useGetUser } from "../../Features/login";
import ScrollToColor from "../ScrollToColor/ScrollToColor";

function ResponsiveAppBar(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const user = useGetUser();
  const location = useLocation();
  const navigate = useNavigate();
  const config = useGetConfig();
  const post = usePost();
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const pages = useMemo(() => {
    const pages = [];
    if (user?.right >= USER_RIGHT.USER || !config?.connectionMode) {
      pages.push({ name: "Concours", link: "/contests" });
    }
    if (user && user.right > USER_RIGHT.USER) {
      pages.push({ name: "Créer un concours", link: "/create-contest" });
    }

    if (user?.right >= USER_RIGHT.USER) {
      pages.push({ name: "Inscriptions", link: "/my-subscriptions" });
    }

    if (user && user.right >= USER_RIGHT.ADMIN) {
      pages.push({ name: "Administration", link: "/administration" });
    }

    return pages;
  }, [user, config?.connectionMode]);

  const userName = useMemo(() => {
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return "";
  }, [user]);

  const shortName = useMemo(() => {
    if (config?.club?.shortName) {
      //replace space with -
      return config?.club?.shortName.replace(/\s/gi, "-");
    }
    if (config?.club?.name) {
      return config?.club?.name.replace(/\s/gi, "-");
    }
    return "";
  }, [config]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars
  const handleDisconnect = () => {
    queryClient.invalidateQueries({ queryKey: ["payment-method"] });
    setAnchorElUser(null);
    setAccessToken(null);
    navigate("/");
  };

  const [_, setLocalStorage] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars
  const handleLogin = useCallback(() => {
    setLocalStorage(null);
    navigate("/login");
  }, [setLocalStorage, navigate]);

  const handleLinkClick = useCallback(
    (link) => {
      setAnchorElNav(null);
      navigate(link);
    },
    [setAnchorElNav, navigate]
  );

  const handleAccount = useCallback(() => {
    setAnchorElUser(null);
    navigate("/account");
  }, [setAnchorElUser, navigate]);

  const handleHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleStripeRedirect = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await post("/billing/account/client-session", {
        currentLocation: window.location.href,
      });
      if (res.statusCode === 200) {
        window.location.href = res.data.url;
      } else {
        setError({
          message:
            "Erreur lors de la redirection vers le compte Stripe. Veuillez réessayer.",
          severity: "error",
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [post, setError]);

  const handlePaymentInfos = useCallback(() => {
    setAnchorElUser(null);
    navigate("/payment-infos");
  }, [setAnchorElUser, navigate]);

  return (
    <ScrollToColor {...props}>
      <AppBar position="fixed" elevation={2} sx={{ minHeight: "88px" }}>
        <Toolbar>
          <Avatar
            src={config?.logo}
            onClick={handleHome}
            sx={{
              display: { xs: "flex", md: "flex" },
              mr: 1,
              width: 40,
              cursor: "pointer",
            }}
            title={shortName}
            alt={shortName}
          />
          {shortName.length <= 10 && (
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 1,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                textShadow: "1px 1px 1px #000000",
                fontSize: "1.3rem",
              }}
            >
              {shortName}
            </Typography>
          )}

          {pages.length > 0 && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handleLinkClick(page.link)}
                    selected={page.link === location.pathname}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {shortName.length <= 10 && (
            <Typography
              variant="h5"
              noWrap
              component="a"
              onClick={handleHome}
              sx={{
                mr: 1,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                textShadow: "1px 1px 1px #000000",
                cursor: "pointer",
                fontSize: "1.3rem",
              }}
            >
              {shortName}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleLinkClick(page.link)}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textShadow: "1px 1px 1px #000000",
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {user.email && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Ouvrir les paramètres">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userName} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="account" onClick={handleAccount}>
                  <Typography textAlign="center">Mon compte</Typography>
                </MenuItem>
                {user.hasStripeAccount && (
                  <>
                    <MenuItem
                      key="stripe"
                      onClick={handleStripeRedirect}
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <CircularProgress size={24} sx={{ mr: 1 }} />
                      )}
                      <Typography textAlign="center">Factures</Typography>
                    </MenuItem>

                    <MenuItem>
                      <Typography
                        textAlign="center"
                        onClick={handlePaymentInfos}
                      >
                        Informations de paiement
                      </Typography>
                    </MenuItem>
                  </>
                )}
                <MenuItem key="disconnect" onClick={handleDisconnect}>
                  <Typography textAlign="center">Se déconnecter</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
          {!user.email && (
            <Button
              color="primary"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textShadow: "1px 1px 1px #000000",
                fontSize: "0.7rem",
              }}
              onClick={handleLogin}
            >
              Se connecter
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </ScrollToColor>
  );
}
export default ResponsiveAppBar;
