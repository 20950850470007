import { Button, Grid, Stack } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import useMediaQuery from "@mui/material/useMediaQuery";
import PopoverCounters from "../../PopoverCounters/PopoverCounters";

export default function ContestManageActionBar({
  contest,
  isUserAllowedToArchive,
  onCancelContest,
  onArchiveContest,
  onChangeMailingMode,
  onCloseResgistrations,
  onEdit,
  onChangeFoldings,
  folds,
  counters,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [anchorCounter, setAnchorCounter] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = useCallback(() => {
    if (selectedIndex === 0) {
      onEdit();
    } else if (selectedIndex === 1) {
      onCloseResgistrations();
    } else if (selectedIndex === 2) {
      onCancelContest();
    } else {
      onArchiveContest();
    }
  }, [
    onArchiveContest,
    onCancelContest,
    onEdit,
    onCloseResgistrations,
    selectedIndex,
  ]);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOpenCounters = useCallback((event) => {
    setAnchorCounter(event.currentTarget);
  }, []);

  const handleCloseCounters = useCallback(() => {
    setAnchorCounter(null);
  }, []);

  const notificationElement = useMemo(() => {
    return (
      <Button
        onClick={onChangeMailingMode}
        sx={{ justifyContent: "center", minWidth: "36px" }}
      >
        {contest?.mailingMode === 0 ? (
          <NotificationsOffIcon />
        ) : (
          <NotificationsIcon />
        )}
      </Button>
    );
  }, [contest?.mailingMode, onChangeMailingMode]);

  const md = useMediaQuery("(min-width:600px)");
  const options = useMemo(() => {
    const options = [
      {
        label: "Modifier",
        enabled: contest?.state === 0 || contest?.state === 3,
      },
    ];
    if (contest?.state === 3) {
      options.push({
        label: md ? "Réouvrir les inscriptions" : "Activer inscriptions",
        enabled: contest?.state === 0 || contest?.state === 3,
      });
    } else {
      options.push({
        label: md ? "Clôturer les inscriptions" : "Clore inscriptions",
        enabled: contest?.state === 0 || contest?.state === 3,
      });
    }

    options.push({
      label: "Déplanifier",
      enabled: contest?.state === 0 || contest?.state === 3,
    });

    if (isUserAllowedToArchive) {
      options.push({ label: "Archiver", enabled: true });
    }
    return options;
  }, [isUserAllowedToArchive, md, contest]);

  const colors = useMemo(() => {
    const colors = ["primary"];
    if (contest?.state === 3) {
      colors.push("secondary");
    } else {
      colors.push("warning");
    }
    colors.push("warning");
    if (isUserAllowedToArchive) {
      colors.push("error");
    }
    return colors;
  }, [isUserAllowedToArchive, contest]);

  const countersOpen = useMemo(() => {
    return Boolean(anchorCounter);
  }, [anchorCounter]);

  return (
    <>
      <PopoverCounters
        open={countersOpen}
        onClose={handleCloseCounters}
        anchorEl={anchorCounter}
        counters={counters}
      />
      <Grid container align="left" sx={{ px: 4 }}>
        <Grid align="left" item xs={8} sm={9}>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            fullWidth
            id="action-button-group"
          >
            <Button
              onClick={handleClick}
              color={colors[selectedIndex]}
              disabled={!options[selectedIndex].enabled}
            >
              {options[selectedIndex].label}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="sélection de l'action"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{ width: "40px" }}
              color={colors[selectedIndex]}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 10,
              width: "calc(100% - 120px)",
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            fullWidth
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
        <Grid align="center" xs={4} sm={3} item sx={{ alignSelf: "center" }}>
          <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
            {notificationElement}
            <Button onClick={handleOpenCounters} sx={{ minWidth: "36px" }}>
              <DashboardIcon />
            </Button>
            <Button onClick={onChangeFoldings} sx={{ minWidth: "36px" }}>
              {!folds ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
