import { Card, Typography } from "@mui/material";

import React from "react";

const PaymentMethodDisplay = ({ paymentMethod }) => {
  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h6">Moyen de paiement enregistré</Typography>
      {paymentMethod.data.card && (
        <>
          <Typography variant="body1">
            {paymentMethod.data.card.cardBrand} **** **** ****{" "}
            {paymentMethod.data.card.last4}
          </Typography>
          <Typography variant="body2">
            Date d'expiration: {paymentMethod.data.card.expMonth}/
            {paymentMethod.data.card.expYear}
          </Typography>
          <Typography variant="body2">
            <strong>{paymentMethod.data.card.name}</strong>
          </Typography>
        </>
      )}
      {paymentMethod.data.sepa && (
        <Typography variant="body1">
          IBAN: {paymentMethod.data.sepa.country} ************
          {paymentMethod.data.sepa.last4}
        </Typography>
      )}
    </Card>
  );
};

export default PaymentMethodDisplay;
