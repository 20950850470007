import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useGetConfig } from "../../../Features/config";
import { useResetPassword } from "../../../Features/login";
import Copyright from "../Copyright/Copyright";

const defaultTheme = createTheme();

export default function ResetPassword() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const resetPassword = useResetPassword();
  const { token } = useParams();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);
      const data = new FormData(event.currentTarget);
      const password = data.get("password");
      const password2 = data.get("password2");
      if (password !== password2) {
        setError("Les mots de passe ne correspondent pas");
        return;
      }
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (!passwordRegex.test(password)) {
        setError(
          "Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule et un chiffre."
        );
        return;
      }

      if (await resetPassword(data.get("password"), token)) {
        navigate("/");
      } else {
        setError(
          "Une erreur est survenue, le code de réinitialisation est invalide ou expiré."
        );
      }
    },
    [navigate, resetPassword, token, setError]
  );

  const handleHome = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  const config = useGetConfig();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button onClick={handleHome}>
              <Avatar
                sx={{ m: 1, border: "1px solid #000" }}
                src={config?.logo}
              />
            </Button>
            <Typography component="h1" variant="h5">
              Réinitialisation du mot de passe
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Typography variant="body2" color="text.secondary">
                Veuillez saisir votre nouveau mot de passe.
              </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="password"
                    label="Nouveau mot de passe"
                    name="password"
                    autoComplete="new-password"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="password2"
                    label="Nouveau mot de passe"
                    name="password2"
                    autoComplete="new-password"
                    type="password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Réinitialiser le mot de passe
              </Button>
              {error && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
