import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { BILLING_STATE } from "../../../../Features/enums";

export default function PaidCategories({
  allCategories,
  paidCategories,
  anchorEl,
  onClose,
  billingState,
  onDeleteCategory,
}) {
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const isCategoryPaid = useCallback(
    (category) => {
      return paidCategories.includes(category);
    },
    [paidCategories]
  );

  const acceptDelete = useCallback(
    (description) => {
      return (
        (billingState === BILLING_STATE.TO_BILL ||
          billingState === BILLING_STATE.WAITING_REGISTRATION) &&
        isCategoryPaid(description)
      );
    },
    [billingState, isCategoryPaid]
  );

  const handleDeleteCategory = useCallback(
    (description) => {
      if (onDeleteCategory) {
        onDeleteCategory(description);
      }
    },
    [onDeleteCategory]
  );

  if (!open) {
    return null;
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List>
        {allCategories.map((category) => {
          const isPaid = isCategoryPaid(category.description);
          return (
            <ListItem
              key={category.description}
              sx={{ py: 0.5 }}
              secondaryAction={
                acceptDelete(category.description) ? (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteCategory(category.description)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null
              }
            >
              <ListItemIcon sx={{ minWidth: "36px" }}>
                {isPaid ? (
                  <CheckIcon color="success" />
                ) : (
                  <NotInterestedIcon color="warning" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={category.description}
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
}
