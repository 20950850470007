import Box from "@mui/material/Box";
import React from "react";
import { Outlet } from "react-router-dom";
import { useGetConfig } from "../../Features/config";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function Home() {
  const config = useGetConfig();
  return (
    <>
      <ResponsiveAppBar threshold={150} />
      <Box
        style={{
          height: "400px",
          backgroundImage: `url(${config?.homeBackground})`,
          width: "100%",
          position: "relative",
          top: "-40px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "25% 50%",
        }}
      ></Box>
      <Outlet />
      <Footer />
    </>
  );
}
