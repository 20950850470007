import { useQuery } from "@tanstack/react-query";
import { useGet } from "./apiFetch";
import { useGetUser } from "./login";

export const usePaymentMetods = () => {
  const user = useGetUser();
  const get = useGet();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["payment-method"],
    queryFn: async () => {
      return get(`/billing/account/payment-method`);
    },
    enabled: user !== undefined && user !== null && user.right > 0,
  });

  return {
    data: data?.data,
    isLoading,
    isError,
    error,
  };
};
