import React, { useCallback, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { t } from "@lingui/macro";

export default function Step3({
  contest,
  handleNext,
  handleBack,
  buttonLabel,
  dateKey,
}) {
  const [internalContest, setInternalContest] = useState(contest);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const categories = useMemo(() => {
    return window.config.categories[internalContest.equide][
      internalContest.discipline
    ][internalContest.type];
  }, [
    internalContest.equide,
    internalContest.discipline,
    internalContest.type,
  ]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);
      if (internalContest.multiDays) {
        if (!internalContest.categoriesMultiDays[dateKey]) {
          setError("Veuillez sélectionner au moins une catégorie");
          return;
        }
      } else if (
        !internalContest.categories ||
        internalContest.categories.length === 0
      ) {
        setError("Veuillez sélectionner au moins une catégorie");
        return;
      }
      setIsLoading(true);
      await handleNext({ categories: internalContest.categories });
      setIsLoading(false);
    },
    [
      internalContest.multiDays,
      internalContest.categories,
      internalContest.categoriesMultiDays,
      handleNext,
      dateKey,
    ]
  );

  const handleCategoryChange = useCallback(
    (event) => {
      const { name, checked } = event.target;
      if (!internalContest.categories) {
        internalContest.categories = [];
      }

      if (checked) {
        if (internalContest.multiDays) {
          if (!internalContest.categoriesMultiDays[dateKey]) {
            internalContest.categoriesMultiDays[dateKey] = [];
          }
          internalContest.categoriesMultiDays[dateKey].push(name);

          setInternalContest({
            ...internalContest,
          });
        } else {
          setInternalContest({
            ...internalContest,
            categories: [...internalContest.categories, name],
          });
        }
      } else {
        if (internalContest.multiDays) {
          internalContest.categoriesMultiDays[dateKey] =
            internalContest.categoriesMultiDays[dateKey].filter(
              (category) => category !== name
            );
          setInternalContest({
            ...internalContest,
          });
        } else {
          setInternalContest({
            ...internalContest,
            categories: internalContest.categories.filter(
              (category) => category !== name
            ),
          });
        }
      }
    },
    [dateKey, internalContest]
  );

  const isCategoryChecked = useCallback(
    (category) => {
      if (internalContest.multiDays) {
        if (internalContest.categoriesMultiDays[dateKey] === undefined) {
          return false;
        }
        return internalContest.categoriesMultiDays[dateKey].includes(category);
      } else {
        if (!internalContest.categories) {
          return false;
        }
        return internalContest.categories.includes(category);
      }
    },
    [
      dateKey,
      internalContest.categories,
      internalContest.categoriesMultiDays,
      internalContest.multiDays,
    ]
  );

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mb: 2, minWidth: { sm: "517px" } }}
      >
        <FormGroup sx={{ mt: 4, mb: 4 }}>
          {categories.map((category) => (
            <FormControlLabel
              key={category}
              control={<Switch />}
              label={t({ id: category })}
              name={category}
              checked={isCategoryChecked(category)}
              onChange={handleCategoryChange}
            />
          ))}
          {error && <Box sx={{ color: "red", mt: 2, mb: 2 }}>{error}</Box>}
          <Box sx={{ mb: 2, mt: 2, position: "relative" }}>
            <Button
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
              type="submit"
              disabled={isLoading}
            >
              {buttonLabel}
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "25%",
                  marginTop: "-6px",
                  marginLeft: "-10px",
                }}
              />
            )}
            <Button
              disabled={isLoading}
              onClick={() => handleBack(internalContest)}
              sx={{ mt: 1, mr: 1 }}
            >
              Retour
            </Button>
          </Box>
        </FormGroup>
      </Box>
    </>
  );
}
