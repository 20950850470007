import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { usePost } from "../apiFetch";
import { errorAtom } from "../atom";
import { USER_RIGHT } from "../enums";

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (users, userRight) => {
      const userIndex = users.findIndex((u) => u.email === userRight.email);
      if (userRight.right === USER_RIGHT.BANNED) {
        users.splice(userIndex, 1);
      } else {
        users[userIndex].right = userRight.right;
      }

      queryClient.setQueryData([`users`], {
        data: users,
        statusCode: 200,
      });
    },
    [queryClient]
  );
};

export const useUpdateUserRight = (users) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateUser = useUpdateUser();
  return useMutation({
    mutationFn: async ({ email, right }) => {
      const res = await post(`/users/right`, {
        email: email,
        right: right,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      updateUser(users, res);
    },
    onError: (error) => {
      displayError("Erreur lors de la l'enregistrement des droits");
    },
  });
};
