import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { sortCategories, useGetChipColor } from "../../../Features/categories";
import {
  BILLING_STATE,
  CONTEST_STATE,
  SUBSCRIPTION_STATE,
  useBillingStateDescription,
} from "../../../Features/enums";

import { t } from "@lingui/macro";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import EuroIcon from "@mui/icons-material/Euro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import RedeemIcon from "@mui/icons-material/Redeem";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import moment from "moment-timezone";
import { useNavigate } from "react-router";
import { useGet } from "../../../Features/apiFetch";
import { useUsedCategories } from "../../../Features/categories";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SubscriptionCard({
  subscription,
  filters,
  cancelSubscriptionMutation,
}) {
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const getDisciplineChipColor = useGetChipColor();
  const usedCategories = useUsedCategories();
  const getBillingStateDescription = useBillingStateDescription();
  const get = useGet();

  const isContestPassed = useMemo(() => {
    return moment(subscription.contest.date) <= moment();
  }, [subscription]);

  const isContestCancelled = useMemo(() => {
    return subscription.contest?.state === 1;
  }, [subscription]);

  const isContestArchived = useMemo(() => {
    return subscription.contest?.state === 2;
  }, [subscription]);

  const isCancellable = useMemo(() => {
    const contestDate = moment(subscription.contest.date);
    const weekBeforeContest = moment(contestDate).subtract(7, "days");
    return (
      moment() < weekBeforeContest &&
      subscription.billingState !== BILLING_STATE.PAID &&
      subscription.billingState !== BILLING_STATE.OFFERED &&
      subscription.billingState !== BILLING_STATE.REFUNDED &&
      subscription.billingState !== BILLING_STATE.FAILED &&
      subscription.billingState !== BILLING_STATE.PROCESSING_REFUND &&
      subscription.billingState !== BILLING_STATE.FAILED_REFUND &&
      subscription.subscriptionState === SUBSCRIPTION_STATE.CREATED &&
      (subscription.contest.state === CONTEST_STATE.CREATED ||
        subscription.contest.state === CONTEST_STATE.BLOCKED)
    );
  }, [subscription]);

  const billingStateDescription = useMemo(() => {
    return getBillingStateDescription(subscription.billingState);
  }, [subscription, getBillingStateDescription]);

  const billingIcon = useMemo(() => {
    if (subscription.billingState === BILLING_STATE.PAID) {
      return (
        <EuroIcon
          title={billingStateDescription}
          alt={billingStateDescription}
        />
      );
    } else if (subscription.billingState === BILLING_STATE.OFFERED) {
      return (
        <RedeemIcon
          title={billingStateDescription}
          alt={billingStateDescription}
        />
      );
    } else if (subscription.billingState === BILLING_STATE.REFUNDED) {
      return (
        <AssignmentReturnIcon
          title={billingStateDescription}
          alt={billingStateDescription}
        />
      );
    } else if (subscription.billingState === BILLING_STATE.CANCELED) {
      return (
        <DoNotDisturbAltIcon
          title={billingStateDescription}
          alt={billingStateDescription}
        />
      );
    } else if (
      subscription.billingState === BILLING_STATE.PROCESSING_REFUND ||
      subscription.billingState === BILLING_STATE.PROCESSING
    ) {
      return (
        <HourglassBottomIcon
          title={billingStateDescription}
          alt={billingStateDescription}
        />
      );
    } else if (
      subscription.billingState === BILLING_STATE.FAILED_REFUND ||
      subscription.billingState === BILLING_STATE.FAILED
    ) {
      return (
        <ReportProblemIcon
          title={billingStateDescription}
          alt={billingStateDescription}
        />
      );
    }
    return null;
  }, [subscription, billingStateDescription]);

  const getChipColor = useCallback((category) => {
    return category.isAccepted
      ? "success"
      : category.count > 0
        ? "primary"
        : "warning";
  }, []);

  const isFiltered = useMemo(() => {
    if (filters.length === 0) {
      return false;
    }
    if (
      filters.find((f) => f.value === "contest_passed") &&
      !isContestPassed &&
      !filters.find((f) => f.value === "contest_incoming")
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "contest_incoming") &&
      isContestPassed &&
      !filters.find((f) => f.value === "contest_passed")
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "contest_canceled") &&
      !isContestCancelled
    ) {
      return true;
    }
    if (
      !filters.find((f) => f.value === subscription.contest?.discipline) &&
      filters.some((f) => usedCategories.includes(f.value))
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "sub_state_0") &&
      subscription.subscriptionState !== SUBSCRIPTION_STATE.CREATED &&
      !filters.find((f) => ["sub_state_1", "sub_state_2"].includes(f.value))
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "sub_state_1") &&
      subscription.subscriptionState !== SUBSCRIPTION_STATE.ACCEPTED &&
      !filters.find((f) => ["sub_state_0", "sub_state_2"].includes(f.value))
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "sub_state_2") &&
      subscription.subscriptionState !== SUBSCRIPTION_STATE.DECLINED_CLUB &&
      !filters.find((f) => ["sub_state_0", "sub_state_1"].includes(f.value))
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "bil_state") &&
      subscription.billingState !== BILLING_STATE.PAID &&
      subscription.billingState !== BILLING_STATE.OFFERED &&
      !filters.find((f) => f.value === "notbil_state") &&
      !filters.find((f) => f.value === "errorbil_state")
    ) {
      return true;
    }
    if (
      filters.find((f) => f.value === "notbil_state") &&
      subscription.billingState !== BILLING_STATE.WAITING_REGISTRATION &&
      subscription.billingState !== BILLING_STATE.TO_BILL &&
      subscription.billingState !== BILLING_STATE.REFUNDED &&
      subscription.billingState !== BILLING_STATE.CANCELED &&
      !filters.find((f) => f.value === "bil_state") &&
      !filters.find((f) => f.value === "errorbil_state")
    ) {
      return true;
    }

    if (
      filters.find((f) => f.value === "errorbil_state") &&
      subscription.billingState !== BILLING_STATE.FAILED &&
      subscription.billingState !== BILLING_STATE.REQUIRES_ACTION &&
      subscription.billingState !== BILLING_STATE.REQUIRES_CAPTURE &&
      subscription.billingState !== BILLING_STATE.REQUIRES_CONFIRMATION &&
      subscription.billingState !== BILLING_STATE.REQUIRES_PAYMENT_METHOD &&
      !filters.find((f) => f.value === "bil_state") &&
      !filters.find((f) => f.value === "notbil_state")
    ) {
      return true;
    }

    for (const filter of filters) {
      if (filter.type === "free") {
        const contestPlace = subscription.contest.place.trim().toLowerCase();
        const filterValue = filter.value.trim().toLowerCase();

        if (!contestPlace.includes(filterValue)) {
          return true;
        }
      }
    }

    return false;
  }, [
    filters,
    isContestPassed,
    subscription,
    isContestCancelled,
    usedCategories,
  ]);

  const cardColor = useMemo(() => {
    let background = "inherit";
    let border = null;

    // Background depend on the state of the subscription and the contest
    if (
      subscription.subscriptionState === SUBSCRIPTION_STATE.DECLINED_CLUB ||
      subscription.subscriptionState === SUBSCRIPTION_STATE.DECLINED_USER ||
      isContestCancelled
    ) {
      background = "#ffd9c6";
    }
    if (isContestPassed) {
      background = "#d0f5ff";
    }
    if (isContestArchived) {
      background = "#e0e0e0";
    }

    // Border depend on the billing state
    if (
      subscription.billingState === BILLING_STATE.PAID ||
      subscription.billingState === BILLING_STATE.OFFERED ||
      subscription.billingState === BILLING_STATE.REFUNDED
    ) {
      border = "1px solid #61affe";
    }
    if (
      subscription.billingState === BILLING_STATE.PROCESSING_REFUND ||
      subscription.billingState === BILLING_STATE.PROCESSING
    ) {
      border = "1px solid #fff600";
    } else if (
      subscription.billingState === BILLING_STATE.FAILED_REFUND ||
      subscription.billingState === BILLING_STATE.FAILED
    ) {
      border = "1px solid #ff0000";
    }

    return { background, border };
  }, [isContestArchived, isContestCancelled, isContestPassed, subscription]);

  const handleCancelSubscription = useCallback(
    (category, dateKey) => {
      cancelSubscriptionMutation.mutate({ subscription, category, dateKey });
    },
    [cancelSubscriptionMutation, subscription]
  );

  const handleUpdateSubscription = useCallback(() => {
    navigate(
      `/contests/${subscription.contest.guid}/update/${subscription.guid}`
    );
  }, [subscription, navigate]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleGetInvoiceUrl = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(false);

      const response = await get(`/billing/invoice/${subscription.guid}`);
      if (response.statusCode === 200) {
        window.open(response.data.url, "_blank");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [get, subscription.guid]);

  return (
    <Card
      elevation={4}
      sx={{
        minWidth: 275,
        ml: 4,
        mr: 4,
        mt: 1,
        mb: 1,
        backgroundColor: cardColor.background,
        border: cardColor.border,
        display: isFiltered ? "none" : "",
      }}
    >
      <CardHeader
        title={`${subscription.contest.place} - ${subscription.subscriberName}`}
        action={billingIcon}
      />
      <CardContent sx={{ pb: 0 }}>
        <Stack>
          <Box>
            {Object.keys(subscription?.categories).map((dateKey) => (
              <div style={{ marginBottom: "16px" }} key={dateKey}>
                <Typography variant="subtitle2" component="div" key={dateKey}>
                  {moment(dateKey).format("dddd Do MMMM YYYY")}
                </Typography>
                <div>
                  <Chip
                    label={t({
                      id: subscription.contest.discipline,
                    })}
                    sx={{
                      m: "2px",
                      backgroundColor: getDisciplineChipColor(
                        subscription.contest.discipline
                      )[500],
                      color: "#FFFFFF",
                    }}
                    size="small"
                  />
                  {sortCategories(
                    subscription.contest?.equide,
                    subscription.contest?.discipline,
                    subscription.contest?.type,
                    subscription.categories[dateKey]
                  ).map((category) => (
                    <Chip
                      label={t({ id: category.category })}
                      color={getChipColor(category)}
                      sx={{ m: "2px" }}
                      key={`${dateKey}-${category.category}`}
                      avatar={
                        category.count > 0 ? (
                          <Avatar>{category.count}</Avatar>
                        ) : null
                      }
                      size="small"
                    />
                  ))}
                </div>
              </div>
            ))}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {billingStateDescription}
          </Typography>
          {error && (
            <Typography variant="body2" color="error" sx={{ pt: 1 }}>
              Une erreur est survenue lors de la récupération de la facture.
              <br />
              Afin de régler cette inscription, veuillez utiliser le menu
              "Factures" de votre compte.
            </Typography>
          )}
        </Stack>
      </CardContent>

      <CardActions>
        {isCancellable && (
          <>
            {subscription?.contest?.state === CONTEST_STATE.CREATED && (
              <Button
                size="small"
                color="primary"
                onClick={handleUpdateSubscription}
                variant="contained"
              >
                Modifier l'inscription
              </Button>
            )}
            {subscription?.contest?.state === CONTEST_STATE.BLOCKED && (
              <Button size="small" disabled variant="contained">
                Complet !
              </Button>
            )}
            <Button
              size="small"
              color="warning"
              onClick={() => handleCancelSubscription(null, null)}
              variant="contained"
              sx={{ mr: "auto" }}
            >
              Annuler l'inscription
            </Button>
          </>
        )}
        {subscription?.billingState === BILLING_STATE.FAILED && (
          <Box sx={{ position: "relative", mr: "auto" }}>
            <Button
              size="small"
              color="primary"
              onClick={handleGetInvoiceUrl}
              variant="contained"
              disabled={isLoading}
            >
              Régler la facture
            </Button>{" "}
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        )}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ ml: "auto" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      {subscription.billingState === BILLING_STATE.FAILED && !expanded && (
        <Typography
          variant="body2"
          sx={{ fontStyle: "italic", pl: 2, pb: 1 }}
          color="text.secondary"
        >
          * Si vous venez de régler la facture, veuillez recharger la page
        </Typography>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            Demande faite le{" "}
            {moment
              .utc(subscription.creationDateTime)
              .tz("Europe/Paris")
              .format("Do MMMM YYYY à HH:mm")}
          </Typography>

          {subscription.comments && (
            <Typography variant="body2" color="text.secondary">
              Commentaires: {subscription.comments}
            </Typography>
          )}
          {subscription.updatedByClub && (
            <Typography variant="body2" color="text.secondary">
              Cette inscription a été modifiée par le club
            </Typography>
          )}
          {(subscription.billingState === BILLING_STATE.PAID ||
            subscription.billingState === BILLING_STATE.FAILED_REFUND ||
            subscription.billingState === BILLING_STATE.PROCESSING_REFUND ||
            subscription.billingState === BILLING_STATE.REFUNDED) &&
            subscription.prepaid > 0 && (
              <Typography variant="body2" color="text.secondary">
                Montant de {subscription.prepaid}€, <b>payé</b> le{" "}
                {moment(subscription.paymentDateTime).format("Do MMMM YYYY")}
              </Typography>
            )}
          {(subscription.billingState === BILLING_STATE.FAILED ||
            subscription.billingState === BILLING_STATE.REQUIRES_ACTION ||
            subscription.billingState === BILLING_STATE.REQUIRES_CAPTURE ||
            subscription.billingState === BILLING_STATE.REQUIRES_CONFIRMATION ||
            subscription.billingState ===
              BILLING_STATE.REQUIRES_PAYMENT_METHOD) &&
            subscription.prepaid > 0 && (
              <Typography variant="body2" color="text.secondary">
                A Payer: {subscription.prepaid}€
              </Typography>
            )}
          {subscription.billingState === BILLING_STATE.REFUNDED &&
            subscription.refund && (
              <Typography variant="body2" color="text.secondary">
                <b>Remboursé</b> le{" "}
                {moment(subscription.refund.refundDateTime).format(
                  "Do MMMM YYYY"
                )}
              </Typography>
            )}
          {subscription.declinedReason && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Raison du refus: {subscription.declinedReason}
            </Typography>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}
