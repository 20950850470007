import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  useDelete,
  useGetUserPreferences,
  usePatch,
} from "../../Features/apiFetch";

import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { useSavePreferences } from "../../Features/Mutations/userPreferences";
import { errorAtom } from "../../Features/atom";
import { useGetConfig } from "../../Features/config";
import { useGetUser } from "../../Features/login";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import ConfirmDeleteAccount from "./ConfirmDeleteAccount";

export default function Account() {
  const [isEmailLocked, setIsEmailLocked] = useState(true);
  const [isPasswordLocked, setIsPasswordLocked] = useState(true);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorApi, setErrorApi] = useState(null);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  const user = useGetUser();
  const navigate = useNavigate();
  const patch = usePatch();
  const deleteQuery = useDelete();
  const queryClient = useQueryClient();
  const preferences = useGetUserPreferences();
  const config = useGetConfig();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (user.isLoading) return;

    if (!user || user.right < 0) {
      navigate("/403");
    }
  }, [user, navigate]);

  const handleSubmitEmail = useCallback(
    async (event) => {
      event.preventDefault();

      const data = new FormData(event.currentTarget);
      const email = data.get("email");
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        setErrorEmail("Veuillez saisir une adresse email valide");
        return;
      } else {
        setErrorEmail(null);
      }

      const res = await patch(`/account/user/email`, { email, password: "*" });
      if (res.statusCode === 200) {
        setErrorEmail(null);
        setIsEmailLocked(true);
        queryClient.invalidateQueries(localStorage.getItem("accessToken"));
      } else {
        setErrorEmail("Erreur lors de la mise à jour de l'email");
      }
    },
    [patch, queryClient]
  );

  const handleSubmitPassword = useCallback(
    async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const password = data.get("password");
      const newPassword = data.get("new-password");
      if (password === newPassword) {
        setErrorPassword("Vous ne pouvez pas utiliser le même mot de passe");
        return;
      } else {
        setErrorPassword(null);
      }
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (!passwordRegex.test(newPassword)) {
        setErrorPassword(
          "Le mot de passe doit contenir au moins 8 caractères, une majuscule et un chiffre"
        );
        return;
      } else {
        setErrorPassword(null);
      }

      const res = await patch(`/account/user/password`, {
        password,
        newPassword,
      });

      if (res.statusCode === 200) {
        setErrorPassword(null);
        setIsPasswordLocked(true);
      } else {
        setErrorPassword("Erreur lors de la mise à jour du mot de passe");
      }
    },
    [patch, setErrorPassword]
  );

  const handleEmailLock = useCallback(() => {
    const locked = !isEmailLocked;
    setIsEmailLocked(locked);
    if (locked) {
      setErrorEmail(null);
    }
  }, [isEmailLocked]);

  const handlePasswordLock = useCallback(() => {
    setIsPasswordLocked(!isPasswordLocked);
  }, [isPasswordLocked]);

  const preferencesMutation = useSavePreferences();

  const handleSwitchNewContestEmails = useCallback(
    async (event) => {
      const allowNewContestEmails = event.target.checked;
      await preferencesMutation.mutateAsync({
        preferences: { allowNewContestEmails },
      });
    },
    [preferencesMutation]
  );

  const handleSwitchSubscriptionsEmails = useCallback(
    async (event) => {
      const allowSubscriptionsEmails = event.target.checked;
      await preferencesMutation.mutateAsync({
        preferences: { allowSubscriptionsEmails },
      });
    },
    [preferencesMutation]
  );

  const handleDeleteAccountClub = useCallback(async () => {
    setErrorApi(null);
    const res = await deleteQuery(`/account`);
    if (res.statusCode === 200) {
      setTimeout(() => (window.location = "/"), 2000);
      setError({ message: "Compte supprimé", severity: "success" });
    } else if (res.statusCode === 409) {
      setErrorApi(
        "Vous ne pouvez pas supprimer votre compte : des paiements sont en erreur ou en attente de validation par votre banque."
      );
    } else {
      setErrorApi("Erreur lors de la suppression du compte");
    }
    setConfirmDeleteAccount(false);
  }, [deleteQuery, setError]);

  const handleCancelDeleteAccount = useCallback(() => {
    setConfirmDeleteAccount(false);
  }, []);

  const handleOpenDeleteAccount = useCallback(() => {
    setConfirmDeleteAccount(true);
  }, []);

  if (user.isLoading) return null;

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid
        container
        component="main"
        sx={{ height: "100%", marginTop: "86px" }}
      >
        <ConfirmDeleteAccount
          open={confirmDeleteAccount}
          onCancel={handleCancelDeleteAccount}
          onDeleteClub={handleDeleteAccountClub}
        />
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={1}
          md={4}
          lg={6}
          xl={7}
          sx={{
            backgroundImage: `url(${config?.accountBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 140px)",
          }}
        />
        <Grid
          item
          xs={12}
          sm={11}
          md={8}
          lg={6}
          xl={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 6,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, border: "1px solid #000" }}
              src={config?.logo}
            />
            <Typography component="h1" variant="h5">
              Mon compte
            </Typography>
            {user?.isInternalAccount && (
              <>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmitEmail}
                  sx={{ mt: 3, mx: "auto", width: { xs: "90%", sm: "70%" } }}
                >
                  <FormGroup row>
                    <TextField
                      margin="normal"
                      required
                      sx={{ width: "80%" }}
                      id="email"
                      name="email"
                      label="Adresse email / Login"
                      autoFocus
                      defaultValue={user?.email ?? ""}
                      disabled={isEmailLocked}
                    />
                    <Button onClick={handleEmailLock}>
                      {isEmailLocked ? <LockIcon /> : <LockOpenIcon />}
                    </Button>
                  </FormGroup>
                  {!isEmailLocked && (
                    <Button type="submit" fullWidth variant="text">
                      Enregistrer
                    </Button>
                  )}
                  {errorEmail && (
                    <Typography variant="body1" color="error">
                      {errorEmail}
                    </Typography>
                  )}
                </Box>
                <Divider variant="middle" sx={{ my: 1 }} />
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmitPassword}
                  sx={{ mx: "auto", width: { xs: "90%", sm: "70%" } }}
                >
                  <FormGroup row>
                    <TextField
                      margin="normal"
                      required
                      sx={{ width: "80%" }}
                      id="email"
                      label="Mot de passe"
                      name="password"
                      autoComplete="password"
                      type="password"
                      disabled={isPasswordLocked}
                    />
                    <Button onClick={handlePasswordLock}>
                      {isPasswordLocked ? <LockIcon /> : <LockOpenIcon />}
                    </Button>
                  </FormGroup>
                  {!isPasswordLocked && (
                    <>
                      <TextField
                        margin="normal"
                        required
                        sx={{ width: "80%" }}
                        id="email"
                        label="Nouveau mot de passe"
                        name="new-password"
                        type="password"
                      />
                      <Button type="submit" fullWidth variant="text">
                        Enregistrer
                      </Button>
                    </>
                  )}
                  {errorPassword && (
                    <Typography variant="body1" color="error">
                      {errorPassword}
                    </Typography>
                  )}
                </Box>
              </>
            )}
            <Box sx={{ mx: "auto", width: { xs: "90%", sm: "70%" } }}>
              <Divider variant="middle" sx={{ my: 2 }} />
              <Typography variant="body1" color="text.secondary">
                Préférences
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences?.allowNewContestEmails ?? false}
                      onChange={handleSwitchNewContestEmails}
                    />
                  }
                  label="Recevoir un email lors de l'ajout d'un concours"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={preferences?.allowSubscriptionsEmails ?? false}
                      onChange={handleSwitchSubscriptionsEmails}
                    />
                  }
                  label="Recevoir un email sur les notifications d'inscription"
                />
              </FormGroup>
            </Box>
            <Box
              sx={{
                mx: "auto",
                width: {
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Divider variant="middle" sx={{ my: 1, width: "100%" }} />
              <Button color="warning" onClick={handleOpenDeleteAccount}>
                Supprimer mon compte
              </Button>
              {errorApi && (
                <Box sx={{ mx: "auto", width: { xs: "90%", sm: "70%" } }}>
                  <Typography variant="body1" color="error">
                    {errorApi}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Footer id="FOOTER" />
    </>
  );
}
