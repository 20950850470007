import React, { useMemo } from "react";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { errorAtom } from "../../Features/atom";
import { useRecoilState } from "recoil";

export default function SnackBar() {
  const [error, setError] = useRecoilState(errorAtom);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(null);
  };

  const open = useMemo(() => {
    return error !== null;
  }, [error]);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={error?.severity}
        sx={{ width: "100%" }}
      >
        {error?.message}
      </Alert>
    </Snackbar>
  );
}
