import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  sortCategories,
  useGetAllCategories,
} from "../../../Features/categories";
import {
  BILLING_STATE,
  SUBSCRIPTION_STATE,
  USER_RIGHT,
  useBillingStateDescription,
} from "../../../Features/enums";

import { t } from "@lingui/macro";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EuroIcon from "@mui/icons-material/Euro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import InfoIcon from "@mui/icons-material/Info";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import RedeemIcon from "@mui/icons-material/Redeem";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { usePost } from "../../../Features/apiFetch";
import { errorAtom } from "../../../Features/atom";
import { useGetUser } from "../../../Features/login";
import ModalDialog from "../../ModalDialog/ModalDialog";
import ModalEmail from "../../ModalEmail/ModalEmail";
import FormBillingDetails from "./Components/FormBillingDetails";
import PaidCategories from "./Components/PaidCategories";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SubscriptionCard({
  contest,
  subscription,
  updateCategoryMutation,
  billSubscriptionMutation,
  refundSubscriptionMutation,
  billingDetailsMutation,
  decrementCategoryMutation,
  deleteSubscriptionMutation,
  validateSubscriptionMutation,
  deleteSubscriptionBillingMutation,
  filters,
  folds,
}) {
  const [displayAddDetails, setDisplayAddDetails] = useState(false);
  const [expanded, setExpanded] = useState(folds);
  const [configCancelSubscription, setConfigCancelSubscription] =
    useState(null);
  const [modalEmailOpen, setModalEmailOpen] = useState(false);
  const [anchorPaidCategories, setAnchorPaidCategories] = useState(null);
  const user = useGetUser();
  const categories = useGetAllCategories();
  const post = usePost();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  const navigate = useNavigate();

  useEffect(() => {
    setExpanded(folds);
  }, [folds]);

  const display = useMemo(() => {
    if (filters.length === 0) {
      return true;
    } else {
      const filterSubscription = ["sub_0", "sub_1", "sub_2", "sub_3"];
      const filterBilling = ["bill_0", "bill_1", "bill_2", "bill_3"];
      const filterCategories = categories;

      if (filters.find((f) => f.value === "sub_0")) {
        if (subscription.subscriptionState !== SUBSCRIPTION_STATE.CREATED) {
          const othersCategories = filterSubscription.filter(
            (filter) => filter !== "sub_0"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "sub_1")) {
        if (subscription.subscriptionState !== SUBSCRIPTION_STATE.ACCEPTED) {
          const othersCategories = filterSubscription.filter(
            (filter) => filter !== "sub_1"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "sub_2")) {
        if (
          subscription.subscriptionState !== SUBSCRIPTION_STATE.DECLINED_CLUB
        ) {
          const othersCategories = filterSubscription.filter(
            (filter) => filter !== "sub_2"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "sub_3")) {
        if (
          subscription.subscriptionState !== SUBSCRIPTION_STATE.DECLINED_USER
        ) {
          const othersCategories = filterSubscription.filter(
            (filter) => filter !== "sub_3"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "bill_0")) {
        if (subscription.billingState !== BILLING_STATE.TO_BILL) {
          const othersCategories = filterBilling.filter(
            (filter) => filter !== "bill_0"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "bill_1")) {
        if (subscription.billingState !== BILLING_STATE.PAID) {
          const othersCategories = filterBilling.filter(
            (filter) => filter !== "bill_1"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "bill_2")) {
        if (subscription.billingState !== BILLING_STATE.OFFERED) {
          const othersCategories = filterBilling.filter(
            (filter) => filter !== "bill_2"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }
      if (filters.find((f) => f.value === "bill_3")) {
        if (subscription.billingState !== BILLING_STATE.REFUNDED) {
          const othersCategories = filterBilling.filter(
            (filter) => filter !== "bill_3"
          );
          if (
            !filters.some((filter) => othersCategories.includes(filter.value))
          ) {
            return false;
          }
        }
      }

      if (filters.some((filter) => filterCategories.includes(filter.value))) {
        for (const f in filters) {
          for (const key in subscription.categories) {
            const tabSubscriptions = subscription.categories[key];
            if (tabSubscriptions.find((c) => c.category === filters[f].value)) {
              return true;
            }
          }
        }
        return false;
      }

      for (const filter of filters) {
        if (filter.type === "free") {
          const subscriberName = subscription.subscriberName
            .trim()
            .toLowerCase();
          const filterValue = filter.value.trim().toLowerCase();

          if (!subscriberName.includes(filterValue)) {
            return false;
          }
        }
      }
    }

    return true;
  }, [filters, subscription, categories]);

  const getBilingStateDescription = useBillingStateDescription();
  const billingStateDescription = useMemo(() => {
    return getBilingStateDescription(subscription.billingState);
  }, [getBilingStateDescription, subscription]);

  const accordionColor = useMemo(() => {
    if (
      subscription.subscriptionState === SUBSCRIPTION_STATE.ACCEPTED &&
      subscription.billingState !== BILLING_STATE.PAID &&
      subscription.billingState !== BILLING_STATE.OFFERED &&
      subscription.billingState !== BILLING_STATE.REFUNDED &&
      subscription.billingState !== BILLING_STATE.PROCESSING_REFUND &&
      subscription.billingState !== BILLING_STATE.FAILED_REFUND &&
      subscription.billingState !== BILLING_STATE.FAILED
    ) {
      return { background: "#eaf6f0", border: "1px solid #49cc90" };
    } else if (
      subscription.subscriptionState === SUBSCRIPTION_STATE.DECLINED_CLUB &&
      subscription.billingState === BILLING_STATE.WAITING_REGISTRATION
    ) {
      return { background: "#f6e6e6", border: "1px solid #f44336" };
    } else if (
      subscription.subscriptionState === SUBSCRIPTION_STATE.DECLINED_CLUB &&
      subscription.billingState === BILLING_STATE.TO_BILL
    ) {
      return { background: "#eaf6f0", border: "1px solid #49cc90" };
    } else if (
      subscription.billingState === BILLING_STATE.PAID ||
      subscription.billingState === BILLING_STATE.OFFERED
    ) {
      return { background: "#ecf3fa", border: "1px solid #61affe" };
    } else if (
      subscription.billingState === BILLING_STATE.REFUNDED ||
      subscription.billingState === BILLING_STATE.PROCESSING_REFUND
    ) {
      return { background: "#feffe3", border: "1px solid #fff600" };
    } else if (subscription.billingState === BILLING_STATE.FAILED_REFUND) {
      return { background: "#feffe3", border: "1px solid #ff0000" };
    } else if (subscription.billingState === BILLING_STATE.FAILED) {
      return {
        background: "rgba(249, 62, 62, 0.1)",
        border: "1px solid #ff0000",
      };
    } else return { background: "inherit", border: "inherit" };
  }, [subscription]);

  const isSubscriptionUnlock = useMemo(
    () =>
      subscription.billingState === BILLING_STATE.WAITING_REGISTRATION ||
      subscription.billingState === BILLING_STATE.TO_BILL,
    [subscription]
  );

  const displayBillingDetails = useMemo(() => {
    return (
      subscription.subscriptionState === SUBSCRIPTION_STATE.ACCEPTED &&
      subscription.billingState !== BILLING_STATE.PAID &&
      subscription.billingState !== BILLING_STATE.OFFERED &&
      subscription.billingState !== BILLING_STATE.REFUNDED &&
      subscription.billingState !== BILLING_STATE.PROCESSING_REFUND &&
      subscription.billingState !== BILLING_STATE.FAILED_REFUND
    );
  }, [subscription]);

  const paymentInfosColor = useMemo(() => {
    if (contest?.payment?.mode === "online") {
      /// Check if subscription contains all payment infos
      for (const billingInfo of contest.payment.billings) {
        if (!subscription?.prepaidItems.includes(billingInfo.description)) {
          return "warning";
        }
      }
    }
    return "success";
  }, [contest, subscription]);

  const handleAddCategory = useCallback(
    async (event, category, dateKey) => {
      event.preventDefault();
      updateCategoryMutation.mutate({ subscription, category, dateKey });
    },
    [subscription, updateCategoryMutation]
  );

  const isSubscribed = useCallback(
    (date, category) => {
      if (subscription.categoriesSubscribed[date] !== undefined) {
        if (
          subscription.categoriesSubscribed[date].find(
            (c) => c.category === category.category
          )
        ) {
          return true;
        }
      }
      return false;
    },
    [subscription]
  );

  const getChipColor = useCallback(
    (date, category) => {
      //If the count of the category is 0, the category is refused
      if (category.count === 0) {
        return "warning";
      }

      if (isSubscribed(date, category)) {
        return "success";
      }
      return "primary";
    },
    [isSubscribed]
  );

  const handleBillSubscription = useCallback(
    (billed) => {
      billSubscriptionMutation.mutate({ subscription, billed });
    },
    [billSubscriptionMutation, subscription]
  );

  const handleRefundSubscription = useCallback(() => {
    refundSubscriptionMutation.mutate({ subscription });
  }, [refundSubscriptionMutation, subscription]);

  const handleAddDetails = useCallback(() => {
    setDisplayAddDetails(!displayAddDetails);
  }, [setDisplayAddDetails, displayAddDetails]);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  const handleValidateAll = useCallback(() => {
    validateSubscriptionMutation.mutate({ subscription });
  }, [subscription, validateSubscriptionMutation]);

  const handleConfirmDeleteSubscription = useCallback(() => {
    deleteSubscriptionMutation.mutate({ subscription });
    setConfigCancelSubscription(null);
  }, [subscription, deleteSubscriptionMutation, setConfigCancelSubscription]);

  const handleDeleteSubscription = useCallback(() => {
    setConfigCancelSubscription({
      title: "Supprimer l'inscription",
      content:
        "Êtes-vous sûr de vouloir supprimer cette inscription ? Le participant ne recevra pas de mail et l'opération est irréversible.",
      onConfirm: handleConfirmDeleteSubscription,
    });
  }, [handleConfirmDeleteSubscription]);

  const handleCloseCancelSubscription = useCallback(() => {
    setConfigCancelSubscription(null);
  }, [setConfigCancelSubscription]);

  const handleDecrementCategory = useCallback(
    (event, category, dateKey) => {
      event.preventDefault();
      decrementCategoryMutation.mutate({ subscription, category, dateKey });
    },
    [decrementCategoryMutation, subscription]
  );

  const handleSendEmail = useCallback(() => {
    setModalEmailOpen(true);
  }, [setModalEmailOpen]);

  const handleSubmitEmail = useCallback(
    async (message) => {
      setModalEmailOpen(false);
      try {
        await post(
          `/contest/register/email?club=${window?.config?.club.guid}`,
          {
            message,
            guid: subscription.guid,
          }
        );
        setError({
          message: "Email envoyé",
          severity: "success",
        });
      } catch (e) {
        setError({
          message: "Erreur lors de l'envoi de l'email",
          severity: "error",
        });
      }
    },
    [post, subscription, setError]
  );

  const handleEditSubscription = useCallback(() => {
    navigate(
      `/contests/${subscription.contestGuid}/update/${subscription.guid}`
    );
  }, [navigate, subscription]);

  const handleOpenPaidCategories = useCallback((event) => {
    setAnchorPaidCategories(event.currentTarget);
  }, []);

  const handleDeletePaidCategory = useCallback(
    (category) => {
      deleteSubscriptionBillingMutation.mutate({ subscription, category });
    },
    [deleteSubscriptionBillingMutation, subscription]
  );

  return (
    <>
      <ModalDialog
        config={configCancelSubscription}
        onClose={handleCloseCancelSubscription}
      />
      <ModalEmail
        open={modalEmailOpen}
        onClose={() => setModalEmailOpen(false)}
        onSubmit={handleSubmitEmail}
        dest={subscription.contactEmail}
      />
      <PaidCategories
        allCategories={contest?.payment?.billings}
        paidCategories={subscription?.prepaidItems}
        anchorEl={anchorPaidCategories}
        onClose={() => setAnchorPaidCategories(null)}
        billingState={subscription.billingState}
        onDeleteCategory={handleDeletePaidCategory}
      />
      <Card
        sx={{
          backgroundColor: accordionColor.background,
          border: accordionColor.border,
          display: display ? "block" : "none",
        }}
        elevation={6}
      >
        <CardHeader
          title={subscription.subscriberName}
          sx={{ py: 1 }}
          action={
            <>
              <IconButton
                aria-label="Supprimer"
                onClick={handleDeleteSubscription}
                sx={{
                  display:
                    (subscription.subscriptionState ===
                      SUBSCRIPTION_STATE.CREATED ||
                      subscription.subscriptionState ===
                        SUBSCRIPTION_STATE.DECLINED_CLUB) &&
                    isSubscriptionUnlock
                      ? "inline-block"
                      : "none",
                }}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
              <IconButton
                aria-label="Tout valider"
                onClick={handleValidateAll}
                sx={{
                  display:
                    subscription.subscriptionState ===
                      SUBSCRIPTION_STATE.CREATED && isSubscriptionUnlock
                      ? "inline-block"
                      : "none",
                }}
              >
                <DoneAllOutlinedIcon />
              </IconButton>
            </>
          }
        />
        <CardContent sx={{ py: 0 }}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            {Object.keys(subscription.categories).map((key) => (
              <div key={key}>
                <Chip
                  label={moment(key).format("Do MMMM YYYY")}
                  sx={{ mb: 1 }}
                  color="primary"
                  variant="outlined"
                />

                {sortCategories(
                  contest?.equide,
                  contest?.discipline,
                  contest?.type,
                  subscription.categories[key]
                ).map((category) => (
                  <Chip
                    key={`${key}-${category.category}`}
                    label={t({ id: category.category })}
                    color={getChipColor(key, category)}
                    sx={{ ml: 1, mb: 1 }}
                    onClick={
                      isSubscriptionUnlock &&
                      category.count > 0 &&
                      !isSubscribed(key, category)
                        ? (event) =>
                            handleAddCategory(event, category.category, key)
                        : undefined
                    }
                    avatar={
                      category.count > 0 ? (
                        <Avatar>{category.count}</Avatar>
                      ) : null
                    }
                    onDelete={
                      category.count > 0 && isSubscriptionUnlock
                        ? (event) => {
                            handleDecrementCategory(
                              event,
                              category.category,
                              key
                            );
                          }
                        : null
                    }
                    deleteIcon={<RemoveCircleIcon />}
                  />
                ))}
              </div>
            ))}
            {subscription.comments && (
              <>
                <Divider variant="middle" />
                <Typography variant="body2" color="text.secondary">
                  Commentaires: {subscription.comments}
                </Typography>
              </>
            )}
            {contest?.payment?.mode === "online" && (
              <Typography variant="body2" sx={{ ml: 1 }}>
                <b>{billingStateDescription}</b>
              </Typography>
            )}
          </Stack>
        </CardContent>
        <CardActions disableSpacing sx={{ pt: 0 }}>
          <IconButton aria-label="Envoyer un message" onClick={handleSendEmail}>
            <MailOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label="Modifier"
            onClick={handleEditSubscription}
            disabled={!isSubscriptionUnlock}
          >
            <EditIcon />
          </IconButton>
          {contest?.payment?.mode === "online" && (
            <IconButton
              aria-label="Infos paiement"
              color={paymentInfosColor}
              onClick={handleOpenPaidCategories}
            >
              <EuroIcon />
            </IconButton>
          )}
          {contest?.payment?.mode === "online" &&
            subscription?.countUserFailedPayments > 0 && (
              <IconButton
                aria-label="Warning payment"
                color="warning"
                title={`Cet utilisateur a ${subscription.countUserFailedPayments} paiements en échec.`}
              >
                <WarningIcon />
              </IconButton>
            )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Détails"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Contact: {subscription.contactEmail}
              </Typography>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Demande faite le{" "}
                {moment
                  .utc(subscription.creationDateTime)
                  .tz("Europe/Paris")
                  .format("Do MMMM YYYY à HH:mm")}
              </Typography>
              {subscription.updatedByClub && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Inscription modifiée par le club
                </Typography>
              )}

              {!displayAddDetails && subscription.billingDetail && (
                <>
                  <Divider variant="middle" sx={{ mt: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.primary"
                    sx={{ mt: 1, ml: 1, mb: 1 }}
                  >
                    <b>Détails de facturation:</b> {subscription.billingDetail}
                  </Typography>
                </>
              )}
              {subscription.billingState === BILLING_STATE.FAILED_REFUND && (
                <Typography variant="body2" sx={{ ml: 1, mt: 1 }}>
                  Vous pouvez redemander le remboursement dans la section
                  Facturation de l'onglet Administration.
                  <br />
                  Si le problème persiste, contactez le support technique à
                  l'adresse suivante:{" "}
                  <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                    {process.env.REACT_APP_EMAIL}
                  </a>
                </Typography>
              )}

              <Divider variant="middle" sx={{ mb: 1 }} />
              <ButtonGroup
                variant="contained"
                fullWidth
                sx={{
                  display: { xs: "none", sm: "inherit", md: "inherit" },
                }}
              >
                {contest?.payment?.mode !== "online" && (
                  <Button
                    color="success"
                    startIcon={<InfoIcon />}
                    onClick={handleAddDetails}
                    disabled={!displayBillingDetails}
                  >
                    Détails
                  </Button>
                )}
                {user.right >= USER_RIGHT.ADMIN && (
                  <>
                    <Button
                      startIcon={<EuroIcon />}
                      endIcon={
                        subscription.billingState === BILLING_STATE.PAID ? (
                          <CheckIcon />
                        ) : null
                      }
                      disabled={
                        subscription.billingState !== BILLING_STATE.TO_BILL &&
                        subscription.billingState !== BILLING_STATE.FAILED
                      }
                      onClick={() => handleBillSubscription(true)}
                    >
                      {subscription.billingState === BILLING_STATE.PAID
                        ? "Facturé"
                        : "Facturer"}
                    </Button>
                    <Button
                      disabled={subscription.billingState !== 1}
                      startIcon={<RedeemIcon />}
                      endIcon={
                        subscription.billingState === BILLING_STATE.OFFERED ? (
                          <CheckIcon />
                        ) : null
                      }
                      color="secondary"
                      onClick={() => handleBillSubscription(false)}
                    >
                      {subscription.billingState === BILLING_STATE.OFFERED
                        ? "Offert"
                        : "Offrir"}
                    </Button>
                    <Button
                      disabled={
                        subscription.billingState !== BILLING_STATE.PAID
                      }
                      startIcon={<AssignmentReturnIcon />}
                      endIcon={
                        subscription.billingState === BILLING_STATE.REFUNDED ? (
                          <CheckIcon />
                        ) : null
                      }
                      onClick={handleRefundSubscription}
                    >
                      {subscription.billingState === BILLING_STATE.REFUNDED
                        ? "Remboursé"
                        : "Rembourser"}
                    </Button>
                  </>
                )}
              </ButtonGroup>
              <ButtonGroup
                variant="contained"
                fullWidth
                sx={{ display: { xs: "inherit", sm: "none", md: "none" } }}
              >
                {contest?.payment?.mode !== "online" && (
                  <Button
                    color="success"
                    onClick={handleAddDetails}
                    disabled={!displayBillingDetails}
                  >
                    <InfoIcon />
                  </Button>
                )}
                {user.right >= USER_RIGHT.ADMIN && (
                  <>
                    <Button
                      disabled={subscription.billingState !== 1}
                      onClick={() => handleBillSubscription(true)}
                      endIcon={
                        subscription.billingState === BILLING_STATE.PAID ? (
                          <CheckIcon />
                        ) : null
                      }
                    >
                      <EuroIcon />
                    </Button>
                    <Button
                      disabled={subscription.billingState !== 1}
                      color="secondary"
                      onClick={() => handleBillSubscription(false)}
                      endIcon={
                        subscription.billingState === BILLING_STATE.OFFERED ? (
                          <CheckIcon />
                        ) : null
                      }
                    >
                      <RedeemIcon />
                    </Button>
                    <Button
                      disabled={
                        subscription.billingState !== BILLING_STATE.PAID
                      }
                      endIcon={
                        subscription.billingState === BILLING_STATE.REFUNDED ? (
                          <CheckIcon />
                        ) : null
                      }
                      onClick={handleRefundSubscription}
                    >
                      <AssignmentReturnIcon />
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Stack>
            {displayAddDetails && (
              <FormBillingDetails
                subscription={subscription}
                onSubmited={() => setDisplayAddDetails(false)}
                billingDetailsMutation={billingDetailsMutation}
              />
            )}

            {subscription.declinedReason && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Raison du refus: {subscription.declinedReason}
              </Typography>
            )}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}
