import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDelete, usePost } from "../apiFetch";
import { errorAtom, loadingAtom } from "../atom";

import { useCallback } from "react";
import { useRecoilState } from "recoil";

const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (contestGuid, subscriptions, subscription) => {
      const subscriptionIndex = subscriptions.findIndex(
        (s) => s.guid === subscription.guid
      );
      subscriptions[subscriptionIndex] = subscription;

      queryClient.setQueryData([`subscriptions_${contestGuid}`], {
        data: subscriptions,
        statusCode: 200,
      });
      queryClient.invalidateQueries(["contests", "user-subscriptions"]);
    },
    [queryClient]
  );
};

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useUpdateSubscriptionCategory = (contestGuid, subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription, category, dateKey }) => {
      setLoading(true);
      const res = await post(`/contest/manage/validate-category-subscription`, {
        guid: subscription.guid,
        category: category,
        dateKey: dateKey,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError("Erreur lors de l'enregistrement des catégories inscrites");
      setLoading(false);
    },
  });
};

export const useBillSubscription = (contestGuid, subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription, billed }) => {
      setLoading(true);
      const res = await post(`/contest/manage/bill-subscription`, {
        guid: subscription.guid,
        billed,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError("Erreur lors de l'enregistrement de la facturation");
      setLoading(false);
    },
  });
};

export const useRefundSubscription = (contestGuid, subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription }) => {
      setLoading(true);
      const res = await post(`/contest/manage/refund-subscription`, {
        guid: subscription.guid,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError("Erreur lors de l'enregistrement du remboursement");
      setLoading(false);
    },
  });
};

export const useBillingDetails = (contestGuid, subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription, details }) => {
      setLoading(true);
      const res = await post(`/contest/manage/add-billing-details`, {
        guid: subscription.guid,
        details,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError(
        "Erreur lors de l'enregistrement des informations de facturation"
      );
      setLoading(false);
    },
  });
};

export const useDecrementSubscriptionCategory = (
  contestGuid,
  subscriptions
) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription, category, dateKey }) => {
      setLoading(true);
      const res = await post(
        `/contest/manage/decrement-category-subscription`,
        {
          guid: subscription.guid,
          category: category,
          dateKey: dateKey,
        }
      );

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError(
        "Erreur lors de l'enregistrement des informations de facturation"
      );
      setLoading(false);
    },
  });
};

export const useDeleteSubscription = (contestGuid, subscriptions) => {
  const deleteMethod = useDelete();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars

  return useMutation({
    mutationFn: async ({ subscription }) => {
      setLoading(true);
      const res = await deleteMethod(
        `/contest/manage/subscription/${subscription.guid}`
      );

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      queryClient.setQueryData([`subscriptions_${contestGuid}`], {
        data: subscriptions.filter((s) => s.guid !== res.guid),
        statusCode: 200,
      });

      queryClient.invalidateQueries(["contests", "user-subscriptions"]);
      setLoading(false);
    },
    onError: (error) => {
      displayError("Erreur lors de la suppression de l'inscription");
      setLoading(false);
    },
  });
};

export const useValidateSubscription = (contestGuid, subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription, category, dateKey }) => {
      setLoading(true);
      const res = await post(`/contest/manage/validate-subscription`, {
        guid: subscription.guid,
      });

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError("Erreur lors de la validation de l'inscription");
      setLoading(false);
    },
  });
};

export const useDeleteSubscriptionBilling = (contestGuid, subscriptions) => {
  const deleteMethod = useDelete();
  const displayError = useDisplayError();
  const updateSubscription = useUpdateSubscription();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  return useMutation({
    mutationFn: async ({ subscription, category }) => {
      setLoading(true);
      var res = await deleteMethod(
        `/contest/manage/subscription/${subscription.guid}/billing-item`,
        {
          description: category,
        }
      );

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updateSubscription(contestGuid, subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError("Erreur lors de la suppression de la facturation");
      setLoading(false);
    },
  });
};
