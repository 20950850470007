import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { errorAtom } from "../../../Features/atom";
import { useFacebookLogin } from "../../../Features/login";
import { useRecoilState } from "recoil";

export default function Facebook() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const login = useFacebookLogin();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  //get : code, state, error

  useEffect(() => {
    if (searchParams.get("error")) {
      console.warn(
        "Failed to login on Facebook",
        searchParams.get("error_reason")
      );
      navigate("/login");
      setError({ message: "Facebook login error", severity: "error" });
    }

    const state = localStorage.getItem("FB_STATE");
    if (searchParams.get("state") !== state) {
      console.warn("State mismatch on Facebook login");
      navigate("/login");
      setError({ message: "State mismatch", severity: "error" });
    }

    const code = searchParams.get("code");
    const fetchAccessToken = async () => {
      console.log("will fetch access token");
      const loginState = await login(code);
      if (loginState) {
        console.log("reload home page");
        navigate("/");
      } else {
        console.warn("Failed to fetch accessToken");
        navigate("/login");
        setError({ message: "Failed to get accessToken", severity: "error" });
      }
    };

    fetchAccessToken();
  }, [searchParams, navigate, login, setError]);

  return <></>;
}
