import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

import { useGetConfig } from "../../../Features/config";
import { useGetUser } from "../../../Features/login";
import Footer from "../../Footer/Footer";
import ResponsiveAppBar from "../../ResponsiveAppBar/ResponsiveAppBar";

export default function UserPaymentHome() {
  const user = useGetUser();
  const navigate = useNavigate();
  const config = useGetConfig();

  useEffect(() => {
    if (user.isLoading) return;

    if (!user || user.right < 0) {
      navigate("/403");
    }
  }, [user, navigate]);

  if (user.isLoading) return null;

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid
        container
        component="main"
        sx={{ height: "100%", marginTop: "86px" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={1}
          md={4}
          lg={6}
          xl={7}
          sx={{
            backgroundImage: `url(${config?.accountBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 140px)",
          }}
        />
        <Grid
          item
          xs={12}
          sm={11}
          md={8}
          lg={6}
          xl={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              mt: 4,
              mb: 0,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, border: "1px solid #000" }}
              src={config?.logo}
            />
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Informations de paiement
            </Typography>

            <Outlet />
          </Box>
        </Grid>
      </Grid>

      <Footer id="FOOTER" />
    </>
  );
}
