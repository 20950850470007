import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetConfig, useIsPCO } from "../../../Features/config";

import { useBillingStatus } from "../../../Features/billing";
import { CLUB_BILLING_STATUS } from "../../../Features/enums";

export default function StepFacturation({
  contest,
  handleNext,
  handleBack,
  isModification,
}) {
  const [internalContest, setInternalContest] = React.useState(contest);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const config = useGetConfig();
  const isPCO = useIsPCO();

  const buttonLabel = isModification
    ? "Modifier le concours"
    : "Créer le concours";

  const billingStatus = useBillingStatus();
  const onlineModeEnabled = useMemo(() => {
    return (
      billingStatus?.billingStatus?.accountStatus === CLUB_BILLING_STATUS.ACTIVE
    );
  }, [billingStatus]);

  const freeContests = useMemo(() => {
    return billingStatus?.billingStatus?.remainingFreeContest ?? 0;
  }, [billingStatus]);

  //Init internalContest with default billing
  useEffect(() => {
    if (internalContest.payment?.billings) {
      return;
    }
    if (config?.defaultBilling) {
      if (!internalContest.payment) {
        internalContest.payment = {
          mode: "club",
          guid: "00000000-0000-0000-0000-000000000000",
        };
      }

      if (!internalContest.payment?.billings) {
        internalContest.payment.billings = [];
      }

      for (let i = 0; i < config.defaultBilling.length; i++) {
        internalContest.payment.billings.push({
          description: config.defaultBilling[i].Description,
          price: internalContest.tda
            ? config.defaultBilling[i].Prices.TDAPriceHT
              ? config.defaultBilling[i].Prices.TDAPriceHT
              : config.defaultBilling[i].Prices.NormalPriceHT
            : config.defaultBilling[i].Prices.NormalPriceHT,
          VAT: config.defaultBilling[i].VAT,
          applyOnEachTurn: config.defaultBilling[i].ApplyOnEachTurn,
          userCancelable: config.defaultBilling[i].UserCancelable,
          allowOverwrite: config.defaultBilling[i].AllowOverwrite,
        });
      }
    }
    setInternalContest({ ...internalContest });
  }, [config, setInternalContest, internalContest]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);
      setIsLoading(true);
      await handleNext({ payment: internalContest.payment });
      setIsLoading(false);
    },
    [handleNext, internalContest.payment]
  );

  const handleBackClick = useCallback(
    (event) => {
      event.preventDefault();
      handleBack(internalContest);
    },
    [handleBack, internalContest]
  );

  const handleBillingValueChange = useCallback(
    (index, value) => {
      if (!internalContest.payment?.billings) {
        internalContest.payment.billings = [];
      }
      internalContest.payment.billings[index] = {
        ...internalContest.payment.billings[index],
        price: value,
      };
      setInternalContest({ ...internalContest });
    },
    [internalContest]
  );

  const handleGetPaymentValue = useCallback(
    (index) => {
      if (!internalContest.payment) {
        internalContest.payment = {
          mode: "club",
          guid: "00000000-0000-0000-0000-000000000000",
        };
      }

      if (
        internalContest.payment?.billings &&
        internalContest.payment?.billings[index]
      ) {
        return internalContest.payment?.billings[index].price;
      } else {
        return internalContest.tda
          ? config.defaultBilling[index].Prices.TDAPriceHT
            ? config.defaultBilling[index].Prices.TDAPriceHT
            : config.defaultBilling[index].Prices.NormalPriceHT
          : config.defaultBilling[index].Prices.NormalPriceHT;
      }
    },
    [config.defaultBilling, internalContest]
  );

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mb: 2 }}>
      <FormGroup sx={{ mt: 4, mb: 4 }}>
        <FormControl required sx={{ mb: 2 }}>
          <FormLabel id="row-radio-buttons-group-label">
            Type de paiement
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-online"
            value={internalContest.payment?.mode || "club"}
            onChange={(event) => {
              setInternalContest({
                ...internalContest,
                payment: {
                  mode: event.target.value,
                },
              });
            }}
          >
            <FormControlLabel
              value="online"
              control={
                <Radio disabled={isModification || !onlineModeEnabled} />
              }
              label="En ligne"
              checked={
                internalContest.payment?.mode === "online" ||
                !internalContest.payment?.mode
              }
            />
            <FormControlLabel
              value="club"
              control={<Radio disabled={isModification} />}
              label="Par le club"
              checked={internalContest.payment?.mode === "club"}
            />
          </RadioGroup>
        </FormControl>
        {isModification && internalContest.payment?.mode === "online" && (
          <Typography
            color="text.secondary"
            sx={{ mb: 2, fontStyle: "italic" }}
          >
            * Les modifications ne sont pas rétroactives. Les paiements déjà
            effectués ne seront pas impactés.
          </Typography>
        )}
        {(internalContest.payment?.mode === "online" ||
          !internalContest.payment?.mode) && (
          <FormControl required sx={{ mb: 2 }}>
            {config?.defaultBilling?.map((billing, index) => {
              return (
                billing.AllowOverwrite && (
                  <TextField
                    key={index}
                    label={`${billing.Description} (€ HT)`}
                    value={handleGetPaymentValue(index)}
                    onChange={(event) => {
                      handleBillingValueChange(index, event.target.value);
                    }}
                    variant="filled"
                    type="number"
                    sx={{ mt: 1 }}
                  />
                )
              );
            })}
          </FormControl>
        )}
        {internalContest.payment?.mode === "club" &&
          !isModification &&
          (isPCO ? (
            <Typography
              color="text.secondary"
              sx={{ mb: 2, fontStyle: "italic", fontSize: "0.875rem" }}
            >
              * GRATUIT pour le PCO.
            </Typography>
          ) : (
            <Typography
              color="text.secondary"
              sx={{ mb: 2, fontStyle: "italic", fontSize: "0.875rem" }}
            >
              * 2,25€ seront facturés pour la gestion de votre concours.
              <br />
              <br />
              {freeContests <= 0 && (
                <>
                  Si aucun moyen de paiement par défaut n'a été enregistré, le
                  paiement sera demandé à l'étape suivante.
                </>
              )}
              {freeContests > 0 && (
                <>Vous avez {freeContests} concours gratuits restants.</>
              )}
            </Typography>
          ))}

        {error && (
          <Box sx={{ mb: 2 }}>
            <Typography color="red">{error}</Typography>
          </Box>
        )}
        <Box sx={{ mb: 2 }}>
          <div>
            <Button
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
              type="submit"
              disabled={isLoading}
            >
              {buttonLabel}
            </Button>
            <Button
              onClick={handleBackClick}
              sx={{ mt: 1, mr: 1 }}
              disabled={isLoading}
            >
              Retour
            </Button>
          </div>
        </Box>
      </FormGroup>
    </Box>
  );
}
