import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BlockIcon from "@mui/icons-material/Block";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useGetConfig } from "../../../Features/config";
import { USER_RIGHT } from "../../../Features/enums";

export default function UserCard({ user, updateMutation, filters }) {
  const config = useGetConfig();
  const handleUpdateRight = useCallback(
    (event, newRight) => {
      if (newRight !== null) {
        updateMutation.mutate({
          email: user.email,
          right: newRight,
        });
      }
    },
    [updateMutation, user]
  );

  const isFiltered = useMemo(() => {
    if (!filters) return false;
    if (filters.length === 0) return false;
    if (
      user.right !== USER_RIGHT.BANNED &&
      filters.find((f) => f.value === "user_type_-1")
    ) {
      return true;
    }
    if (
      user.right !== USER_RIGHT.GUEST &&
      filters.find((f) => f.value === "user_type_0")
    ) {
      return true;
    }
    if (
      user.right !== USER_RIGHT.USER &&
      filters.find((f) => f.value === "user_type_1")
    ) {
      return true;
    }
    if (
      user.right !== USER_RIGHT.MODERATOR &&
      filters.find((f) => f.value === "user_type_2")
    ) {
      return true;
    }
    if (
      user.right !== USER_RIGHT.ADMIN &&
      filters.find((f) => f.value === "user_type_3")
    ) {
      return true;
    }

    for (const filter of filters) {
      if (filter.type === "free") {
        const lastName = user.lastName.trim().toLowerCase();
        const firstName = user.firstName.trim().toLowerCase();
        const email = user.email.trim().toLowerCase();
        const filterValue = filter.value.trim().toLowerCase();

        if (
          !lastName.includes(filterValue) &&
          !firstName.includes(filterValue) &&
          !email.includes(filterValue)
        ) {
          return true;
        }
      }
    }

    return false;
  }, [filters, user]);

  return (
    <Accordion
      sx={{
        minWidth: 275,
        ml: 4,
        mr: 4,
        display: isFiltered ? "none" : "",
      }}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="h6"
          sx={{ textTransform: "capitalize" }}
        >{`${user.firstName} ${user.lastName}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="subtitle1" sx={{ ml: 2 }}>
          {user.email}
        </Typography>
      </AccordionDetails>
      <AccordionActions sx={{ justifyContent: "space-between" }}>
        <ToggleButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          value={user.right}
          color="primary"
          onChange={handleUpdateRight}
          exclusive
        >
          <ToggleButton
            value={-1}
            sx={{ display: config?.connectionMode === 2 ? "" : "none" }}
          >
            <BlockIcon />
            <Typography
              sx={{
                display: { xs: "none", sm: "inherit" },
                ml: 1,
                textTransform: "lowercase",
              }}
            >
              Supprimé
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={0}
            sx={{ display: config?.connectionMode === 2 ? "" : "none" }}
          >
            <QuestionMarkIcon />
            <Typography
              sx={{
                display: { xs: "none", sm: "inherit" },
                ml: 1,
                textTransform: "lowercase",
              }}
            >
              Non validé
            </Typography>
          </ToggleButton>

          <ToggleButton value={1}>
            <PersonIcon />
            <Typography
              sx={{
                display: { xs: "none", sm: "inherit" },
                ml: 1,
                textTransform: "lowercase",
              }}
            >
              Adhérent
            </Typography>
          </ToggleButton>
          {
            <ToggleButton value={2} color="warning">
              <EngineeringIcon />
              <Typography
                sx={{
                  display: { xs: "none", sm: "inherit" },
                  ml: 1,
                  textTransform: "lowercase",
                }}
              >
                Employé
              </Typography>
            </ToggleButton>
          }
          <ToggleButton value={3} color="error">
            <AdminPanelSettingsIcon />
            <Typography
              sx={{
                display: { xs: "none", sm: "inherit" },
                ml: 1,
                textTransform: "lowercase",
              }}
            >
              Administrateur
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </AccordionActions>
    </Accordion>
  );
}
