import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";

export default function Maintenance() {
  const handleClose = () => {
    window.location.reload();
  };

  return (
    <Dialog open={window.main_parameters?.maintenance || false}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Site en maintenance
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Nous effectuons une opération de mise à jour qui nécessite
          l'indisponibilité du site pendant quelques minutes.
        </Typography>
        <Typography gutterBottom>
          Veuillez nous excuser pour la gêne occasionnée.
        </Typography>
        <Typography gutterBottom>
          Vous pourrez vous reconnecter dans quelques minutes.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Recharger la page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
