import { Button, FormControl, Grid, TextField } from "@mui/material";
import React, { useCallback } from "react";

export default function FormBillingDetails({
  subscription,
  onSubmited,
  billingDetailsMutation,
}) {
  const handleEnterBillingDetails = useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const details = formData.get("billing-details");
      billingDetailsMutation.mutate({ subscription, details });
      onSubmited();
    },
    [billingDetailsMutation, onSubmited, subscription]
  );

  return (
    <FormControl
      sx={{ mt: 2 }}
      fullWidth
      component="form"
      noValidate
      onSubmit={handleEnterBillingDetails}
    >
      <Grid container spacing={0} fullWidth alignItems="flex-end">
        <Grid xs={12} sm={9} md={10} item>
          <TextField
            id="billing-details"
            name="billing-details"
            label="Détails de facturation"
            placeholder="Détails de facturation"
            multiline
            fullWidth
            defaultValue={subscription.billingDetail}
            sx={{ backgroundColor: "white" }}
          />
        </Grid>
        <Grid xs={12} sm={3} md={2} item>
          <Button
            variant="contained"
            color="primary"
            sx={{ m: 2 }}
            type="submit"
          >
            Envoyer
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}
