import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
} from "@mui/material";
import { blue, deepPurple, indigo, lightBlue, red } from "@mui/material/colors";
import React, { useCallback } from "react";

import { t } from "@lingui/macro";

export default function PopoverCounters({ open, anchorEl, onClose, counters }) {
  const getColors = useCallback((key, value) => {
    if (key === "toConfirm") {
      return { bgcolor: red[100], color: red[600] };
    } else if (key === "confirmed") {
      return { bgcolor: deepPurple[100], color: deepPurple[600] };
    } else {
      if (value <= 0) {
        return { bgcolor: lightBlue[100], color: lightBlue[600] };
      } else if (value <= 10) {
        return { bgcolor: blue[100], color: blue[600] };
      } else if (value <= 20) {
        return { bgcolor: indigo[100], color: indigo[600] };
      }
    }
  }, []);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List>
        {Object.entries(counters).map(([key, value]) => {
          if (key !== "mySubscriptions") {
            return (
              <ListItem key={key}>
                <ListItemAvatar>
                  <Avatar sx={{ ...getColors(key, value) }}>{value}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={t({ id: `counter_${key}` })} />
              </ListItem>
            );
          } else {
            return null;
          }
        })}
      </List>
    </Popover>
  );
}
