import { useQuery } from "@tanstack/react-query";

export const useGetConfig = () => {
  const queryConfig = useQuery({
    queryKey: ["config"],
    queryFn: () => {
      return { ...window.config };
    },
  });

  return { ...queryConfig.data };
};

export const useIsPCO = () => {
  const config = useGetConfig();
  return config?.club?.guid === "b2353b1d-d807-46ba-825f-2d8907f35657";
};
