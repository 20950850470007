import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import React from "react";

export default function ModalDialog({ config, onClose }) {
  return (
    <Dialog
      open={config !== null}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {config?.title && (
        <DialogTitle id="alert-dialog-title">{config?.title}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {config?.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        {config?.onConfirm && (
          <Button onClick={config?.onConfirm} autoFocus>
            Confirmer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
