import { useMutation, useQueryClient } from "@tanstack/react-query";
import { errorAtom, loadingAtom } from "../atom";

import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { usePost } from "../apiFetch";
import { BILLING_STATE } from "../enums";

const useUpdatePayment = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (subscriptions, subscription) => {
      const subscriptionIndex = subscriptions.findIndex(
        (s) => s.guid === subscription.guid
      );
      subscriptions[subscriptionIndex] = subscription;

      //If the payment is successful, we remove the subscription from the pending payments
      if (
        subscription.paymentStatus === BILLING_STATE.PAID ||
        subscription.paymentStatus === BILLING_STATE.OFFERED
      ) {
        subscriptions = subscriptions.filter(
          (s) => s.guid !== subscription.guid
        );
      }

      queryClient.setQueryData(["pending_payments"], {
        data: subscriptions,
        statusCode: 200,
      });

      queryClient.invalidateQueries(["subscriptions"]);
    },
    [queryClient]
  );
};

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useRetryPayment = (subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  const updatePayment = useUpdatePayment();
  return useMutation({
    mutationFn: async ({ guid }) => {
      setLoading(true);
      const res = await post(`/billing/payments/retry/${guid}`);
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updatePayment(subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError(error);
      setLoading(false);
    },
  });
};

export const useCancelPayment = (subscriptions) => {
  const post = usePost();
  const displayError = useDisplayError();
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars
  const updatePayment = useUpdatePayment();
  return useMutation({
    mutationFn: async ({ guid }) => {
      setLoading(true);
      const res = await post(`/billing/payments/void/${guid}`);
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updatePayment(subscriptions, res);
      setLoading(false);
    },
    onError: (error) => {
      displayError(error);
      setLoading(false);
    },
  });
};
