import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

import React from "react";

export default function ConfirmDeleteAccount({ open, onDeleteClub, onCancel }) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <DialogTitle id="confirm-delete-title">Suppression du compte</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-description">
          Attention ! Cette action est irréversible.
          <br />
          <br />
          Si vous vous êtes inscrit à un ou plusieurs concours, le nom du
          cavalier ne sera pas supprimé. <br />
          <br />
          Les références sur votre e-mail seront supprimées.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction="column" spacing={2}>
          <Button onClick={onDeleteClub} color="warning" variant="outlined">
            Supprimer mon compte
          </Button>
          <Button
            onClick={onCancel}
            autoFocus
            color="primary"
            variant="contained"
          >
            Annuler
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
