import { Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { USER_RIGHT } from "../../../Features/enums";

export const ContestRegisterNotAvailable = ({ user }) => {
  const navigate = useNavigate();
  const handleRedirectConnect = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  const handleRedirectPayment = useCallback(() => {
    navigate("/payment-infos");
  }, [navigate]);
  return user?.right < USER_RIGHT.USER ? (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="body2" sx={{ m: 2 }}>
        Vous devez être connecté pour vous inscrire à ce concours.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRedirectConnect}
        sx={{ m: 2 }}
      >
        Se connecter
      </Button>
    </Box>
  ) : (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="body2" sx={{ m: 2 }}>
        Vous devez définir un moyen de paiement pour pouvoir vous inscrire à ce
        concours.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleRedirectPayment}
        sx={{ m: 2 }}
      >
        Préférences de paiement
      </Button>
    </Box>
  );
};
