import React, { useMemo } from "react";

import { t } from "@lingui/macro";
import { Stack } from "@mui/material";
import { useUsedCategories } from "../../../Features/categories";
import Filter from "../../Filter";
import Sort from "../../Sort";

export default function SubscriptionsFilter({ onChange, onSort }) {
  const categories = useUsedCategories();

  const filterItems = useMemo(() => {
    const filters = [];
    filters.push({ type: "header", label: "Disciplines" });
    categories.forEach((category) => {
      filters.push({
        type: "item",
        label: t({ id: category }),
        value: category,
      });
    });
    filters.push({ type: "header", label: "Inscription" });
    filters.push({ type: "item", label: "En attente", value: "sub_state_0" });
    filters.push({ type: "item", label: "Acceptée", value: "sub_state_1" });
    filters.push({ type: "item", label: "Refusée", value: "sub_state_2" });

    filters.push({ type: "header", label: "Facturation" });
    filters.push({ type: "item", label: "Non facturé", value: "notbil_state" });
    filters.push({ type: "item", label: "Facturé", value: "bil_state" });
    filters.push({
      type: "item",
      label: "Echec de paiement",
      value: "errorbil_state",
    });
    filters.push({ type: "header", label: "Concours" });
    filters.push({ type: "item", label: "A venir", value: "contest_incoming" });
    filters.push({ type: "item", label: "Passé", value: "contest_passed" });
    filters.push({ type: "item", label: "Annulée", value: "contest_canceled" });
    return filters;
  }, [categories]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: {
          xl: "end",
          lg: "end",
          md: "end",
          sm: "center",
          xs: "center",
        },
        mt: {
          sm: 1,
          md: 0,
          xs: 1,
        },
      }}
    >
      <Filter
        items={filterItems}
        allowFreeValue={true}
        localStorageKey="subscriptions-filter"
        onChange={onChange}
        defaultItems={[{ value: "contest_incoming" }]}
      />
      <Sort
        defaultSort="contest_date_asc"
        values={[
          "contest_date_desc",
          "contest_date_asc",
          "contest_name_desc",
          "contest_name_asc",
          "subscriber_name_desc",
          "subscriber_name_asc",
        ]}
        localStorageKey="subscriptions-sort"
        onChange={onSort}
      />
    </Stack>
  );
}
