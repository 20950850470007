import { Breadcrumbs, Link, Typography } from "@mui/material";

import React from "react";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbComponent({ items }) {
  const navigate = useNavigate();
  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      sx={{ pl: 4, pt: "14px", pb: "7px" }}
    >
      {items.map((item) => {
        if (item.link) {
          return (
            <Link
              underline="hover"
              key={item.label}
              color="inherit"
              onClick={() => navigate(item.link)}
              sx={{ cursor: "pointer" }}
              variant="h6"
            >
              {item.label}
            </Link>
          );
        } else {
          return (
            <Typography
              variant="h6"
              align="left"
              key={item.label}
              color="text.primary"
            >
              {item.label}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}
