import { Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCancelPayment,
  useRetryPayment,
} from "../../../Features/Mutations/failedPayments";

import { useQuery } from "@tanstack/react-query";
import { useGet } from "../../../Features/apiFetch";
import Sort from "../../Sort";
import PaymentErrorItem from "./PaymentErrorItem";

const PaymentError = () => {
  const [sort, setSort] = useState("contest_date_asc");

  const get = useGet();
  const payments = useQuery({
    queryKey: ["pending_payments"],
    queryFn: async () => {
      return get("/billing/payments/pending");
    },
  });

  useEffect(() => {
    console.log("payments", payments?.data?.data);
  }, [payments]);

  const retryPaymentMutation = useRetryPayment(payments.data?.data);
  const cancelPaymentMutation = useCancelPayment(payments.data?.data);

  const filteredPayments = useMemo(() => {
    if (!payments.data || payments.isLoading) {
      return [];
    }

    const result = payments.data.data;

    // apply sort
    result.sort((a, b) => {
      if (sort === "first_name_desc") {
        return b.userFirstName.localeCompare(a.userFirstName);
      } else if (sort === "first_name_asc") {
        return a.userFirstName.localeCompare(b.userFirstName);
      } else if (sort === "last_name_desc") {
        return b.userLastName.localeCompare(a.userLastName);
      } else if (sort === "last_name_asc") {
        return a.userLastName.localeCompare(b.userLastName);
      } else if (sort === "email_desc") {
        return b.userEmail.localeCompare(a.userEmail);
      } else if (sort === "email_asc") {
        return a.userEmail.localeCompare(b.userEmail);
      } else if (sort === "subscriber_name_desc") {
        return b.subscriberName.localeCompare(a.subscriberName);
      } else if (sort === "subscriber_name_asc") {
        return a.subscriberName.localeCompare(b.subscriberName);
      } else if (sort === "contest_name_desc") {
        return b.contestName.localeCompare(a.contestName);
      } else if (sort === "contest_name_asc") {
        return a.contestName.localeCompare(b.contestName);
      } else if (sort === "amount_desc") {
        return b.amountDue - a.amountDue;
      } else if (sort === "amount_asc") {
        return a.amountDue - b.amountDue;
      } else if (sort === "contest_date_desc") {
        return new Date(b.date) - new Date(a.date);
      } else if (sort === "contest_date_asc") {
        return new Date(a.date) - new Date(b.date);
      }
      return 0;
    });

    return result;
  }, [payments, sort]);

  const handleSortChange = useCallback((newSort) => {
    setSort(newSort);
  }, []);

  const handleRetryPayment = useCallback(
    (guid) => {
      retryPaymentMutation.mutate({ guid });
    },
    [retryPaymentMutation]
  );

  const handleCancelPayment = useCallback(
    (guid) => {
      cancelPaymentMutation.mutate({ guid });
    },
    [cancelPaymentMutation]
  );

  if (payments.isLoading) {
    return <Stack>Chargement en cours...</Stack>;
  }

  return (
    <Stack sx={{ p: 1, mt: 2 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          pb: 2,
          justifyContent: {
            xl: "end",
            lg: "end",
            md: "end",
            sm: "center",
            xs: "center",
          },
        }}
      >
        <Sort
          defaultSort="contest_date_asc"
          values={[
            "first_name_desc",
            "first_name_asc",
            "last_name_desc",
            "last_name_asc",
            "email_desc",
            "email_asc",
            "subscriber_name_desc",
            "subscriber_name_asc",
            "contest_name_desc",
            "contest_name_asc",
            "amount_desc",
            "amount_asc",
            "contest_date_desc",
            "contest_date_asc",
          ]}
          localStorageKey="adm-billing-sort"
          onChange={handleSortChange}
        />
      </Stack>
      {filteredPayments?.length === 0 && (
        <Typography>Aucun paiement en attente</Typography>
      )}
      {filteredPayments?.map((payment) => (
        <PaymentErrorItem
          key={payment.guid}
          payment={payment}
          onRetry={handleRetryPayment}
          onCancel={handleCancelPayment}
        />
      ))}
    </Stack>
  );
};

export default PaymentError;
