import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useGetConfig } from "../../../Features/config";
import { useLocalStorage } from "../../../Features/localStorage";

const defaultTheme = createTheme();

export default function ValidateAccount() {
  const navigate = useNavigate();
  const { token } = useParams();
  const config = useGetConfig();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars

  const handleHome = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    const validateAccount = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/account/activate-account-guid`,
        {
          method: "POST",
          body: JSON.stringify({
            guid: token,
            club: config?.club.guid,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status !== 200) {
        setError(true);
      } else {
        const data = await res.json();
        setAccessToken(data.accessToken);
        setSuccess(true);
      }

      setTimeout(() => {
        navigate("/");
      }, 10000);
    };
    if (config?.club?.guid && !success && !error) {
      validateAccount();
    }
  }, [config, error, navigate, success, token, setAccessToken]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button onClick={handleHome}>
              <Avatar
                sx={{ m: 1, border: "1px solid #000" }}
                src={config?.logo}
              />
            </Button>
            {!success && !error && (
              <>
                <Typography
                  component="span"
                  variant="body"
                  sx={{ textAlign: "center", m: 2 }}
                >
                  Veuillez patienter pendant la validation de votre compte.
                </Typography>
                <CircularProgress color="inherit" sx={{ my: 8 }} />
              </>
            )}
            {success && (
              <>
                <Typography
                  component="span"
                  variant="body"
                  sx={{ textAlign: "center", m: 2 }}
                  color="success"
                >
                  Votre compte a bien été validé.
                  <br /> Vous allez être redirigé vers l'accueil dans quelques
                  secondes.
                </Typography>
              </>
            )}
            {error && (
              <>
                <Typography
                  component="span"
                  variant="body"
                  sx={{ textAlign: "center", m: 2 }}
                  color="error"
                >
                  Une erreur est survenue lors de la validation de votre compte.
                  <br />
                  Veuillez le créer à nouveau.
                  <br />
                  <br /> Vous allez être redirigé vers l'accueil dans quelques
                  secondes.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
