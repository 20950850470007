import { useMutation, useQueryClient } from "@tanstack/react-query";

import { errorAtom } from "../atom";
import { useCallback } from "react";
import { usePatch } from "../apiFetch";
import { useRecoilState } from "recoil";

const useUpdatePreferences = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (preferences) => {
      queryClient.setQueryData([`user-preferences`], {
        data: preferences,
        statusCode: 200,
      });
    },
    [queryClient]
  );
};

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useSavePreferences = () => {
  const post = usePatch();
  const displayError = useDisplayError();
  const updatePreferences = useUpdatePreferences();
  return useMutation({
    mutationFn: async ({ preferences }) => {
      const res = await post(`/account/preferences`, preferences);

      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (res) => {
      // Update the subscriptions with res
      updatePreferences(res);
    },
    onError: (error) => {
      displayError("Erreur lors de la sauvegarde des préférences");
    },
  });
};
