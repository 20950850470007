import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useCallback, useState } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { usePost } from "../../../Features/apiFetch";
import { useGetConfig } from "../../../Features/config";
import Copyright from "../Copyright/Copyright";

const defaultTheme = createTheme();

export default function ForgetAccount() {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const navigate = useNavigate();
  const post = usePost();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      await post("/account/password-lost", {
        email: data.get("email"),
        club: window?.config?.club.guid,
      });
      setIsRequestSent(true);
    },
    [post]
  );

  const handleHome = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  const config = useGetConfig();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button onClick={handleHome}>
              <Avatar
                sx={{ m: 1, border: "1px solid #000" }}
                src={config?.logo}
              />
            </Button>
            <Typography component="h1" variant="h5">
              Mot de passe oublié
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Typography variant="body2" color="text.secondary">
                Veuillez saisir votre adresse email pour recevoir un lien de
                réinitialisation de votre mot de passe.
              </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Adresse email"
                    name="email"
                    autoComplete="email"
                    disabled={isRequestSent}
                  />
                </Grid>
              </Grid>
              {!isRequestSent && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Envoi du lien de réinitialisation
                </Button>
              )}
              {isRequestSent && (
                <>
                  <Typography variant="body2" color="text.secondary">
                    Un email vous a été envoyé.
                  </Typography>
                  <Button onClick={handleHome}> Retour à l'accueil </Button>
                </>
              )}
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
