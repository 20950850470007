import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { useGet } from "../../../Features/apiFetch";
import { errorAtom } from "../../../Features/atom";

const BillingPending = () => {
  const [isLoading, setIsLoading] = useState(false);
  const get = useGet();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const queryClient = useQueryClient();

  const handleFinalizeSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await get("/billing/account/link-onboarding");
      if (res.statusCode === 200) {
        window.location.href = res.data.returnUrl;
      } else {
        setError({
          message:
            "Erreur lors de la redirection vers le compte Stripe. Veuillez réessayer.",
          severity: "error",
        });
      }
    } catch (error) {
      setError({
        message:
          "Erreur lors de la redirection vers le compte Stripe. Veuillez réessayer.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
      queryClient.invalidateQueries("billingStatus");
    }
  }, [get, setError, queryClient]);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Création du compte Stripe
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "left" }}>
        Vous devez finaliser la création de votre compte Stripe pour pouvoir
        accepter les paiements en ligne pour les inscriptions de vos membres aux
        concours.
        <br />
        <br />
        Certaines données n'ont pas été renseignées et sont nécessaires pour
        finaliser la création de votre compte Stripe.
        <br />
        Il se peut que le processus de création de compte Stripe prenne quelques
        minutes. Si tout a été correctement renseigné, rechargez la page pour
        voir l'état de votre compte dans quelques minutes.
      </Typography>
      <Typography
        variant="body2"
        sx={{ mt: 2, textAlign: "left", fontStyle: "italic" }}
      >
        Stripe est un service réservé aux professionnels et aux associations.
        <br />
        Durant la création de votre compte, vous devrez fournir votre numéro de
        SIRET ou SIREN, ainsi que l'IBAN du compte où vous recevrez les
        paiements.
      </Typography>
      <Box sx={{ position: "relative" }}>
        <Button
          sx={{ mt: 2 }}
          onClick={handleFinalizeSubscription}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          C'est parti !
        </Button>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-6px",
              marginLeft: "-10px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default BillingPending;
