import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";

import { USER_RIGHT } from "../../../Features/enums";
import { isStripeEnabledAtom } from "../../../Features/atom";
import { useGetUser } from "../../../Features/login";
import useLegacyEffect from "../../../Features/legacyEffect";
import { useNavigate } from "react-router-dom";
import { usePost } from "../../../Features/apiFetch";
import { useRecoilState } from "recoil";

export const UserPaymentConfiguration = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkoutSession, setCheckoutSession] = useState(null);
  const [loadStripe, setLoadStripe] = useState(null);

  const [isStripeEnabled] = useRecoilState(isStripeEnabledAtom);

  useEffect(() => {
    if (isStripeEnabled) {
      import("@stripe/stripe-js").then((module) => {
        setLoadStripe(() => module.loadStripe);
      });
    }
  }, [isStripeEnabled]);

  const user = useGetUser();
  const post = usePost();
  const navigate = useNavigate();

  useLegacyEffect(() => {
    const createPayment = async () => {
      setError(null);
      setIsLoading(true);
      const result = await post("/billing/account/setup-intent");

      if (result.statusCode !== 200) {
        setError(result.error);
      } else {
        setIsLoading(false);
        setCheckoutSession({
          ...result.data,
        });
      }
    };

    if (isStripeEnabled) {
      createPayment();
    } else {
      setIsLoading(false);
    }
  }, [post, isStripeEnabled]);

  useEffect(() => {
    if (user.isLoading) return;

    if (!user || user.right < USER_RIGHT.USER) {
      navigate("/403");
    }
  }, [user, navigate]);

  const stripePromise = useMemo(() => {
    if (!checkoutSession) {
      return null;
    }
    if (!isStripeEnabled) {
      return null;
    }

    const stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
      {
        stripeAccount: checkoutSession.stripeAccount,
      }
    );
    return stripePromise;
  }, [checkoutSession, isStripeEnabled, loadStripe]);

  const options = useMemo(() => {
    if (!checkoutSession) {
      return null;
    }

    const options = {
      // passing the client secret obtained in step 3
      clientSecret: checkoutSession.clientSecret,
    };
    return options;
  }, [checkoutSession]);

  return (
    <Container
      sx={{
        height: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ my: 4 }} />
          <Typography variant="body" sx={{ textAlign: "center" }}>
            Veuillez patienter...
            <br />
            Nous chargeons le service de paiement.
          </Typography>
        </Box>
      ) : !isLoading && error ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            my: 2,
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Alert severity="error">
            Le service de paiement nous a retourné une erreur.
            <br />
            Si le problème persiste, veuillez contacter le support à l'adresse
            suivante :{" "}
            <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
              {process.env.REACT_APP_EMAIL}
            </a>
          </Alert>
        </Box>
      ) : !isStripeEnabled ? (
        <Alert severity="warning">
          Le service de paiement est actuellement désactivé.
          <br />
          Veuillez autoriser les cookies pour Stripe afin de pouvoir utiliser ce
          service.
          <br />
          <a href="#tarteaucitron">Cookies</a>
        </Alert>
      ) : (
        <Box sx={{ p: 2, background: "#5a5aa8", borderRadius: 2 }}>
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      )}
    </Container>
  );
};
